const WITH_ENVIORNMENT_BRSR_FORM_FIELDS = {
    SectionA: [
      {
        type: "accordion",
        label: "PRODUCT & SERVICES",
        tables: [
          {
            label:
              "Details of business activities (accounting for 90% of the turnover):",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  value: "a",
                  type: "static",
                  id: "0-0-0-0",
                },
                {
                  value: "Description of Main Activity",
                  type: "static",
                  id: "0-0-0-0",
                },
                {
                  id: "0-0-0-0",
                  type: "text",
                  key: "Description of Main Activity",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "b",
                  type: "static",
                  id: "0-0-0-1",
                },
                {
                  value: "Description of Business Activity",
                  type: "static",
                  id: "0-0-0-1",
                },
                {
                  id: "0-0-0-1",
                  type: "text",
                  key: "Description of Business Activity",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "c",
                  type: "static",
                  id: "0-0-0-2",
                },
                {
                  value: "% of Turnover of the entity",
                  type: "static",
                  id: "0-0-0-2",
                },
                {
                  id: "0-0-0-2",
                  type: "text",
                  key: "% of Turnover of the entity",
                  valKey: "value",
                },
              ],
            ],
          },
          {
            label:
              "Products/Services sold by the entity (accounting for 90% of the entity’s Turnover)",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  value: "a",
                  type: "static",
                  id: "0-0-1-0",
                },
                {
                  value: "Product/Service",
                  type: "static",
                  id: "0-0-1-0",
                },
                {
                  id: "0-0-1-0",
                  type: "text",
                  key: "Product/Service",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "b",
                  type: "static",
                  id: "0-0-1-1",
                },
                {
                  value: "NIC Code",
                  type: "static",
                  id: "0-0-1-1",
                },
                {
                  id: "0-0-1-1",
                  type: "text",
                  key: "NIC Code",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "c",
                  type: "static",
                  id: "0-0-1-2",
                },
                {
                  value: "% of total Turnover contributed",
                  type: "static",
                  id: "0-0-1-2",
                },
                {
                  id: "0-0-1-2",
                  type: "text",
                  key: "% of total Turnover contributed",
                  valKey: "value",
                },
              ],
            ],
          },
        ],
      },
      {
        type: "accordion",
        label: "Operations",
        tables: [
          {
            label:
              "Number of locations where plants and/or operations/offices of the entity are situated",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  value: "a",
                  type: "static",
                  id: "0-1-0-0",
                },
                {
                  value: "Location",
                  type: "static",
                  id: "0-1-0-0",
                },
                {
                  id: "0-1-0-0",
                  type: "text",
                  key: "Location",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "b",
                  type: "static",
                  id: "0-1-0-1",
                },
                {
                  value: "National",
                  type: "static",
                  id: "0-1-0-1",
                },
                {
                  id: "0-1-0-1",
                  type: "text",
                  key: "National",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "c",
                  type: "static",
                  id: "0-1-0-2",
                },
                {
                  value: "International",
                  type: "static",
                  id: "0-1-0-2",
                },
                {
                  id: "0-1-0-2",
                  type: "text",
                  key: "International",
                  valKey: "value",
                },
              ],
            ],
          },
          {
            label: "Markets served by the entity",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  value: "a",
                  type: "static",
                  id: "0-1-1-0",
                },
                {
                  value: "Number of locations",
                  type: "static",
                  id: "0-1-1-0",
                },
                {
                  id: "0-1-1-0",
                  type: "text",
                  key: "Number of locations",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "b",
                  type: "static",
                  id: "0-1-1-1",
                },
                {
                  value: "National (No. of States)",
                  type: "static",
                  id: "0-1-1-1",
                },
                {
                  id: "0-1-1-1",
                  type: "text",
                  key: "National (No. of States)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "c",
                  type: "static",
                  id: "0-1-1-2",
                },
                {
                  value: "International (No. of Countries)",
                  type: "static",
                  id: "0-1-1-2",
                },
                {
                  id: "0-1-1-2",
                  type: "text",
                  key: "International (No. of Countries)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "d",
                  type: "static",
                  id: "0-1-1-3",
                },
                {
                  value:
                    "What is the contribution of exports as a percentage of the total turnover of the entity?",
                  type: "static",
                  id: "0-1-1-3",
                },
                {
                  id: "0-1-1-3",
                  type: "text",
                  key: "Exports percentage of turnover",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "e",
                  type: "static",
                  id: "0-1-1-4",
                },
                {
                  value: "Brief type of customer",
                  type: "static",
                  id: "0-1-1-4",
                },
                {
                  id: "0-1-1-4",
                  type: "text",
                  key: "Brief type of customer",
                  valKey: "value",
                },
              ],
            ],
          },
          {
            label: "Employees and workers",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "0-1-2-0",
                },
                {
                  type: "static",
                  value: "Employee",
                  colSpan: 2,
                  id: "0-1-2-0",
                },
              ],
              [
                {
                  type: "static",
                  value: "i",
                  id: "0-1-2-1",
                },
                {
                  type: "static",
                  value: "Permanent Employee",
                  colSpan: 2,
                  id: "0-1-2-1",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-2",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-2",
                },
                {
                  id: "0-1-2-2",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-3",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-3",
                },
                {
                  id: "0-1-2-3",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-4",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-4",
                },
                {
                  id: "0-1-2-4",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "ii",
                  id: "0-1-2-5",
                },
                {
                  type: "static",
                  value: "Contract Employee",
                  colSpan: 2,
                  id: "0-1-2-5",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-6",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-6",
                },
                {
                  id: "0-1-2-6",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-7",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-7",
                },
                {
                  id: "0-1-2-7",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-8",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-8",
                },
                {
                  id: "0-1-2-8",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "0-1-2-9",
                },
                {
                  type: "static",
                  value: "Workers",
                  colSpan: 2,
                  id: "0-1-2-9",
                },
              ],
              [
                {
                  type: "static",
                  value: "i",
                  id: "0-1-2-10",
                },
                {
                  type: "static",
                  value: "Permanent Workers",
                  colSpan: 2,
                  id: "0-1-2-10",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-11",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-11",
                },
                {
                  id: "0-1-2-11",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-12",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-12",
                },
                {
                  id: "0-1-2-12",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-13",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-13",
                },
                {
                  id: "0-1-2-13",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "ii",
                  id: "0-1-2-14",
                },
                {
                  type: "static",
                  value: "Contract Workers",
                  colSpan: 2,
                  id: "0-1-2-14",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-15",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-15",
                },
                {
                  id: "0-1-2-15",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-16",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-16",
                },
                {
                  id: "0-1-2-16",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-17",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-17",
                },
                {
                  id: "0-1-2-17",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "0-1-2-18",
                },
                {
                  type: "static",
                  value: "Differently Abled Employees And Workers",
                  colSpan: 2,
                  id: "0-1-2-18",
                },
              ],
              [
                {
                  type: "static",
                  value: "i",
                  id: "0-1-2-19",
                },
                {
                  type: "static",
                  value: "Permanent Employee",
                  colSpan: 2,
                  id: "0-1-2-19",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-20",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-20",
                },
                {
                  id: "0-1-2-20",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-21",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-21",
                },
                {
                  id: "0-1-2-21",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-22",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-22",
                },
                {
                  id: "0-1-2-22",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "ii",
                  id: "0-1-2-23",
                },
                {
                  type: "static",
                  value: "Contract Employee",
                  colSpan: 2,
                  id: "0-1-2-23",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-24",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-24",
                },
                {
                  id: "0-1-2-24",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-25",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-25",
                },
                {
                  id: "0-1-2-25",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-26",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-26",
                },
                {
                  id: "0-1-2-26",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "iii",
                  id: "0-1-2-27",
                },
                {
                  type: "static",
                  value: "Permanent Workers",
                  colSpan: 2,
                  id: "0-1-2-27",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-28",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-28",
                },
                {
                  id: "0-1-2-28",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-29",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-29",
                },
                {
                  id: "0-1-2-29",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-30",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-30",
                },
                {
                  id: "0-1-2-30",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "iv",
                  id: "0-1-2-31",
                },
                {
                  type: "static",
                  value: "Contract Workers",
                  colSpan: 2,
                  id: "0-1-2-31",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-32",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-32",
                },
                {
                  id: "0-1-2-32",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-33",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-33",
                },
                {
                  id: "0-1-2-33",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-34",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-34",
                },
                {
                  id: "0-1-2-34",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "0-1-2-35",
                },
                {
                  type: "static",
                  value: "Turnover rate of Permanent employees and workers",
                  colSpan: 2,
                  id: "0-1-2-35",
                },
              ],
              [
                {
                  type: "static",
                  value: "i",
                  id: "0-1-2-36",
                },
                {
                  type: "static",
                  value: "Permanent Employee",
                  colSpan: 2,
                  id: "0-1-2-36",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-37",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-37",
                },
                {
                  id: "0-1-2-37",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-38",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-38",
                },
                {
                  id: "0-1-2-38",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-39",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-39",
                },
                {
                  id: "0-1-2-39",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
              [
                {
                  type: "static",
                  value: "ii",
                  id: "0-1-2-40",
                },
                {
                  type: "static",
                  value: "Contract Employee",
                  colSpan: 2,
                  id: "0-1-2-40",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-41",
                },
                {
                  type: "static",
                  value: "Male",
                  id: "0-1-2-41",
                },
                {
                  id: "0-1-2-41",
                  type: "text",
                  valKey: "value",
                  key: "Male",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-42",
                },
                {
                  type: "static",
                  value: "Female",
                  id: "0-1-2-42",
                },
                {
                  id: "0-1-2-42",
                  type: "text",
                  valKey: "value",
                  key: "Female",
                },
              ],
              [
                {
                  type: "static",
                  value: "*",
                  id: "0-1-2-43",
                },
                {
                  type: "static",
                  value: "Total",
                  id: "0-1-2-43",
                },
                {
                  id: "0-1-2-43",
                  type: "text",
                  valKey: "value",
                  key: "Total",
                },
              ],
            ],
          },
          {
            label:
              "Name of Holding / Subsidary / Associate companies / joint ventures",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  value: "a",
                  type: "static",
                  id: "0-1-3-0",
                },
                {
                  value:
                    "Name of Holding / Subsidary / Associate companies / joint ventures",
                  type: "static",
                  id: "0-1-3-0",
                },
                {
                  id: "0-1-3-0",
                  type: "text",
                  key: "Name of Holding / Subsidary / Associate companies / joint ventures",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "b",
                  type: "static",
                  id: "0-1-3-1",
                },
                {
                  value:
                    "Indicate whether holding / subsdiary / associate / Joint venture",
                  type: "static",
                  id: "0-1-3-1",
                },
                {
                  id: "0-1-3-1",
                  type: "text",
                  key: "Indicate whether holding / subsdiary / associate / Joint venture",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "c",
                  type: "static",
                  id: "0-1-3-2",
                },
                {
                  value: "% of Share helf by listed entity",
                  type: "static",
                  id: "0-1-3-2",
                },
                {
                  id: "0-1-3-2",
                  type: "text",
                  key: "% of Share helf by listed entity",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "d",
                  type: "static",
                  id: "0-1-3-3",
                },
                {
                  value:
                    "Does the entity indicated at Points A, particiapte in the buisness responsbility initiative of thelisted entity? Yes/No",
                  type: "static",
                  id: "0-1-3-3",
                },
                {
                  id: "0-1-3-3",
                  type: "text",
                  key: "Does the entity indicated at Points A, particiapte in the buisness responsbility initiative of thelisted entity? Yes/No",
                  valKey: "value",
                },
              ],
            ],
          },
          {
            label: "CSR Details",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  value: "a",
                  type: "static",
                  id: "0-1-4-0",
                },
                {
                  value:
                    "Whether CRS is appliable as per person section 135 of companies Act, 2013: 2013: (Yes/No)",
                  type: "static",
                  id: "0-1-4-0",
                },
                {
                  id: "0-1-4-0",
                  type: "text",
                  key: "Whether CRS is appliable as per person section 135 of companies Act, 2013: 2013: (Yes/No)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "b",
                  type: "static",
                  id: "0-1-4-1",
                },
                {
                  value: "Net Worth ( in Rs.)",
                  type: "static",
                  id: "0-1-4-1",
                },
                {
                  id: "0-1-4-1",
                  type: "text",
                  key: "Net Worth ( in Rs.)",
                  valKey: "value",
                },
              ],
            ],
          },
          {
            label:
              "Complaints/Grievances on any of the principles (Principles 1 to 9) under the National Guidelines on Responsible Business Conduct",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  value: "a",
                  type: "static",
                  id: "0-1-5-0",
                },
                {
                  value: "Stakeholder group from whom complaint is received",
                  type: "static",
                  colSpan: 2,
                  id: "0-1-5-0",
                },
              ],
              [
                {
                  value: "i",
                  type: "static",
                  id: "0-1-5-1",
                },
                {
                  value: "Communities",
                  type: "static",
                  colSpan: 2,
                  id: "0-1-5-1",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-2",
                },
                {
                  value: "Grievance Redressal Mechanism in Place (Yes/No)",
                  type: "static",
                  id: "0-1-5-2",
                },
                {
                  id: "0-1-5-2",
                  type: "text",
                  key: "Grievance Redressal Mechanism in Place (Yes/No)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-3",
                },
                {
                  value:
                    "(If Yes, then provide web-link for grievance redress policy)",
                  type: "static",
                  id: "0-1-5-3",
                },
                {
                  id: "0-1-5-3",
                  type: "text",
                  key: "(If Yes, then provide web-link for grievance redress policy)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-4",
                },
                {
                  value: "Number of complaints filed during the year",
                  type: "static",
                  id: "0-1-5-4",
                },
                {
                  id: "0-1-5-4",
                  type: "text",
                  key: "Number of complaints filed during the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-5",
                },
                {
                  value:
                    "Number of complaints pending resolution at close of the year",
                  type: "static",
                  id: "0-1-5-5",
                },
                {
                  id: "0-1-5-5",
                  type: "text",
                  key: "Number of complaints pending resolution at close of the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-6",
                },
                {
                  value: "Remarks",
                  type: "static",
                  id: "0-1-5-6",
                },
                {
                  id: "0-1-5-6",
                  type: "text",
                  key: "Remarks",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "ii",
                  type: "static",
                  id: "0-1-5-7",
                },
                {
                  value: "Investors (other than shareholders)",
                  type: "static",
                  colSpan: 2,
                  id: "0-1-5-7",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-8",
                },
                {
                  value: "Grievance Redressal Mechanism in Place (Yes/No)",
                  type: "static",
                  id: "0-1-5-8",
                },
                {
                  id: "0-1-5-8",
                  type: "text",
                  key: "Grievance Redressal Mechanism in Place (Yes/No)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-9",
                },
                {
                  value:
                    "(If Yes, then provide web-link for grievance redress policy)",
                  type: "static",
                  id: "0-1-5-9",
                },
                {
                  id: "0-1-5-9",
                  type: "text",
                  key: "(If Yes, then provide web-link for grievance redress policy)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-10",
                },
                {
                  value: "Number of complaints filed during the year",
                  type: "static",
                  id: "0-1-5-10",
                },
                {
                  id: "0-1-5-10",
                  type: "text",
                  key: "Number of complaints filed during the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-11",
                },
                {
                  value:
                    "Number of complaints pending resolution at close of the year",
                  type: "static",
                  id: "0-1-5-11",
                },
                {
                  id: "0-1-5-11",
                  type: "text",
                  key: "Number of complaints pending resolution at close of the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-12",
                },
                {
                  value: "Remarks",
                  type: "static",
                  id: "0-1-5-12",
                },
                {
                  id: "0-1-5-12",
                  type: "text",
                  key: "Remarks",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "iii",
                  type: "static",
                  id: "0-1-5-13",
                },
                {
                  value: "Shareholders",
                  type: "static",
                  colSpan: 2,
                  id: "0-1-5-13",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-14",
                },
                {
                  value: "Grievance Redressal Mechanism in Place (Yes/No)",
                  type: "static",
                  id: "0-1-5-14",
                },
                {
                  id: "0-1-5-14",
                  type: "text",
                  key: "Grievance Redressal Mechanism in Place (Yes/No)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-15",
                },
                {
                  value:
                    "(If Yes, then provide web-link for grievance redress policy)",
                  type: "static",
                  id: "0-1-5-15",
                },
                {
                  id: "0-1-5-15",
                  type: "text",
                  key: "(If Yes, then provide web-link for grievance redress policy)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-16",
                },
                {
                  value: "Number of complaints filed during the year",
                  type: "static",
                  id: "0-1-5-16",
                },
                {
                  id: "0-1-5-16",
                  type: "text",
                  key: "Number of complaints filed during the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-17",
                },
                {
                  value:
                    "Number of complaints pending resolution at close of the year",
                  type: "static",
                  id: "0-1-5-17",
                },
                {
                  id: "0-1-5-17",
                  type: "text",
                  key: "Number of complaints pending resolution at close of the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-18",
                },
                {
                  value: "Remarks",
                  type: "static",
                  id: "0-1-5-18",
                },
                {
                  id: "0-1-5-18",
                  type: "text",
                  key: "Remarks",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "iv",
                  type: "static",
                  id: "0-1-5-19",
                },
                {
                  value: "Employees and workers",
                  type: "static",
                  colSpan: 2,
                  id: "0-1-5-19",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-20",
                },
                {
                  value: "Grievance Redressal Mechanism in Place (Yes/No)",
                  type: "static",
                  id: "0-1-5-20",
                },
                {
                  id: "0-1-5-20",
                  type: "text",
                  key: "Grievance Redressal Mechanism in Place (Yes/No)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-21",
                },
                {
                  value:
                    "(If Yes, then provide web-link for grievance redress policy)",
                  type: "static",
                  id: "0-1-5-21",
                },
                {
                  id: "0-1-5-21",
                  type: "text",
                  key: "(If Yes, then provide web-link for grievance redress policy)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-22",
                },
                {
                  value: "Number of complaints filed during the year",
                  type: "static",
                  id: "0-1-5-22",
                },
                {
                  id: "0-1-5-22",
                  type: "text",
                  key: "Number of complaints filed during the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-23",
                },
                {
                  value:
                    "Number of complaints pending resolution at close of the year",
                  type: "static",
                  id: "0-1-5-23",
                },
                {
                  id: "0-1-5-23",
                  type: "text",
                  key: "Number of complaints pending resolution at close of the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-24",
                },
                {
                  value: "Remarks",
                  type: "static",
                  id: "0-1-5-24",
                },
                {
                  id: "0-1-5-24",
                  type: "text",
                  key: "Remarks",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "v",
                  type: "static",
                  id: "0-1-5-25",
                },
                {
                  value: "Customers",
                  type: "static",
                  colSpan: 2,
                  id: "0-1-5-25",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-26",
                },
                {
                  value: "Grievance Redressal Mechanism in Place (Yes/No)",
                  type: "static",
                  id: "0-1-5-26",
                },
                {
                  id: "0-1-5-26",
                  type: "text",
                  key: "Grievance Redressal Mechanism in Place (Yes/No)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-27",
                },
                {
                  value:
                    "(If Yes, then provide web-link for grievance redress policy)",
                  type: "static",
                  id: "0-1-5-27",
                },
                {
                  id: "0-1-5-27",
                  type: "text",
                  key: "(If Yes, then provide web-link for grievance redress policy)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-28",
                },
                {
                  value: "Number of complaints filed during the year",
                  type: "static",
                  id: "0-1-5-28",
                },
                {
                  id: "0-1-5-28",
                  type: "text",
                  key: "Number of complaints filed during the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-29",
                },
                {
                  value:
                    "Number of complaints pending resolution at close of the year",
                  type: "static",
                  id: "0-1-5-29",
                },
                {
                  id: "0-1-5-29",
                  type: "text",
                  key: "Number of complaints pending resolution at close of the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-30",
                },
                {
                  value: "Remarks",
                  type: "static",
                  id: "0-1-5-30",
                },
                {
                  id: "0-1-5-30",
                  type: "text",
                  key: "Remarks",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "vi",
                  type: "static",
                  id: "0-1-5-31",
                },
                {
                  value: "Value Chain Partners",
                  type: "static",
                  colSpan: 2,
                  id: "0-1-5-31",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-32",
                },
                {
                  value: "Grievance Redressal Mechanism in Place (Yes/No)",
                  type: "static",
                  id: "0-1-5-32",
                },
                {
                  id: "0-1-5-32",
                  type: "text",
                  key: "Grievance Redressal Mechanism in Place (Yes/No)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-33",
                },
                {
                  value:
                    "(If Yes, then provide web-link for grievance redress policy)",
                  type: "static",
                  id: "0-1-5-33",
                },
                {
                  id: "0-1-5-33",
                  type: "text",
                  key: "(If Yes, then provide web-link for grievance redress policy)",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-34",
                },
                {
                  value: "Number of complaints filed during the year",
                  type: "static",
                  id: "0-1-5-34",
                },
                {
                  id: "0-1-5-34",
                  type: "text",
                  key: "Number of complaints filed during the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-35",
                },
                {
                  value:
                    "Number of complaints pending resolution at close of the year",
                  type: "static",
                  id: "0-1-5-35",
                },
                {
                  id: "0-1-5-35",
                  type: "text",
                  key: "Number of complaints pending resolution at close of the year",
                  valKey: "value",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-36",
                },
                {
                  value: "Remarks",
                  type: "static",
                  id: "0-1-5-36",
                },
                {
                  type: "text",
                  key: "Remarks",
                  valKey: "value",
                  id: "0-1-5-36",
                },
              ],
              [
                {
                  value: "vii",
                  type: "static",
                  id: "0-1-5-37",
                },
                {
                  value: "Other (please specify)",
                  type: "static",
                  colSpan: 2,
                  id: "0-1-5-37",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-38",
                },
                {
                  value: "Grievance Redressal Mechanism in Place (Yes/No)",
                  type: "static",
                  id: "0-1-5-38",
                },
                {
                  type: "text",
                  key: "Grievance Redressal Mechanism in Place (Yes/No)",
                  valKey: "value",
                  id: "0-1-5-38",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-39",
                },
                {
                  value:
                    "(If Yes, then provide web-link for grievance redress policy)",
                  type: "static",
                  id: "0-1-5-39",
                },
                {
                  type: "text",
                  key: "(If Yes, then provide web-link for grievance redress policy)",
                  valKey: "value",
                  id: "0-1-5-39",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-40",
                },
                {
                  value: "Number of complaints filed during the year",
                  type: "static",
                  id: "0-1-5-40",
                },
                {
                  type: "text",
                  key: "Number of complaints filed during the year",
                  valKey: "value",
                  id: "0-1-5-40",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-41",
                },
                {
                  value:
                    "Number of complaints pending resolution at close of the year",
                  type: "static",
                  id: "0-1-5-41",
                },
                {
                  type: "text",
                  key: "Number of complaints pending resolution at close of the year",
                  valKey: "value",
                  id: "0-1-5-41",
                },
              ],
              [
                {
                  value: "*",
                  type: "static",
                  id: "0-1-5-42",
                },
                {
                  value: "Remarks",
                  type: "static",
                  id: "0-1-5-42",
                },
                {
                  type: "text",
                  key: "Remarks",
                  valKey: "value",
                  id: "0-1-5-42",
                },
              ],
            ],
          },
          {
            label:
              "Overview of the entity’s material responsible business conduct issues",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  value: "a",
                  type: "static",
                  id: "0-1-6-0",
                },
                {
                  value: "Material issue identified",
                  type: "static",
                  id: "0-1-6-0",
                },
                {
                  type: "text",
                  key: "Material issue identified",
                  valKey: "Material issue identified",
                  id: "0-1-6-0",
                },
              ],
              [
                {
                  value: "b",
                  type: "static",
                  id: "0-1-6-1",
                },
                {
                  value: "Indicate whether risk or opportunity (R/O)",
                  type: "static",
                  id: "0-1-6-1",
                },
                {
                  type: "text",
                  key: "Indicate whether risk or opportunity (R/O)",
                  valKey: "Material issue identified",
                  id: "0-1-6-1",
                },
              ],
              [
                {
                  value: "c",
                  type: "static",
                  id: "0-1-6-2",
                },
                {
                  value: "Rationale for identifying the risk / opportunity",
                  type: "static",
                  id: "0-1-6-2",
                },
                {
                  type: "text",
                  key: "Rationale for identifying the risk / opportunity",
                  valKey: "Material issue identified",
                  id: "0-1-6-2",
                },
              ],
              [
                {
                  value: "d",
                  type: "static",
                  id: "0-1-6-3",
                },
                {
                  value: "In case of risk, approach to adapt or mitigate",
                  type: "static",
                  id: "0-1-6-3",
                },
                {
                  type: "text",
                  key: "In case of risk, approach to adapt or mitigate",
                  valKey: "Material issue identified",
                  id: "0-1-6-3",
                },
              ],
              [
                {
                  value: "e",
                  type: "static",
                  id: "0-1-6-4",
                },
                {
                  value:
                    "Financial implications of the risk or opportunity (Indicate positive or negative implications)",
                  type: "static",
                  id: "0-1-6-4",
                },
                {
                  type: "text",
                  key: "Financial implications of the risk or opportunity (Indicate positive or negative implications)",
                  valKey: "Material issue identified",
                  id: "0-1-6-4",
                },
              ],
            ],
          },
        ],
      },
    ],
    SectionB: [
      {
        type: "accordion",
        label:
          "This section is aimed at helping businesses demonstrate the structures, policies and processes put in place towards adopting the NGRBC Principles and Core Elements.",
        tables: [
          {
            label: "Policy and management processes",
            th: [
              "SR",
              "Disclosure Question",
              "P1",
              "P2",
              "P3",
              "P4",
              "P5",
              "P6",
              "P7",
              "P8",
              "P9",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "1.a",
                  id: "1-0-0-0",
                },
                {
                  type: "static",
                  value:
                    "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                  id: "1-0-0-0",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
                {
                  id: "1-0-0-0",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Whether your entity’s policy/policies cover each principle and its core elements of NGRBCs. ( Yes/No)",
                },
              ],
              [
                {
                  type: "static",
                  value: "1.b",
                  id: "1-0-0-1",
                },
                {
                  type: "static",
                  value: "Has the policy been approved by the Board? (Yes/No)",
                  id: "1-0-0-1",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
                {
                  id: "1-0-0-1",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Has the policy been approved by the Board? (Yes/No)",
                },
              ],
              [
                {
                  type: "static",
                  value: "1.c",
                  id: "1-0-0-2",
                },
                {
                  type: "static",
                  value: "Web Link of the Policies, if available",
                  id: "1-0-0-2",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Web Link of the Policies, if available",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Web Link of the Policies, if available",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Web Link of the Policies, if available",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Web Link of the Policies, if available",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Web Link of the Policies, if available",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Web Link of the Policies, if available",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Web Link of the Policies, if available",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Web Link of the Policies, if available",
                },
                {
                  id: "1-0-0-2",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Web Link of the Policies, if available",
                },
              ],
              [
                {
                  type: "static",
                  value: "2",
                  id: "1-0-0-3",
                },
                {
                  type: "static",
                  value:
                    "Whether the entity has translated the policy into procedures. (Yes / No)",
                  id: "1-0-0-3",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
                {
                  id: "1-0-0-3",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Whether the entity has translated the policy into procedures. (Yes / No)",
                },
              ],
              [
                {
                  type: "static",
                  value: "3",
                  id: "1-0-0-4",
                },
                {
                  type: "static",
                  value:
                    "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                  id: "1-0-0-4",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
                {
                  id: "1-0-0-4",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
                },
              ],
              [
                {
                  type: "static",
                  value: "4",
                  id: "1-0-0-5",
                },
                {
                  type: "static",
                  value:
                    "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                  id: "1-0-0-5",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
                {
                  id: "1-0-0-5",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and manpped to each princiaple",
                },
              ],
              [
                {
                  type: "static",
                  value: "5",
                  id: "1-0-0-6",
                },
                {
                  type: "static",
                  value:
                    "Specific commitments, goals and targets set by the entity with defined timelines if any",
                  id: "1-0-0-6",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
                {
                  id: "1-0-0-6",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Specific commitments, goals and targets set by the entity with defined timelines if any",
                },
              ],
              [
                {
                  type: "static",
                  value: "6",
                  id: "1-0-0-7",
                },
                {
                  type: "static",
                  value:
                    "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                  id: "1-0-0-7",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
                {
                  id: "1-0-0-7",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met",
                },
              ],
            ],
          },
          {
            label: "Governance, leadership and oversight",
            th: ["SR", "Disclosure Question", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: 1,
                  id: "1-0-1-0",
                },
                {
                  type: "static",
                  value:
                    "Statement by director responsible for the business responsibility report, highlighting ESG related challenges, targets and achievements (listed entity has flexibility regarding the placement of the disclosure",
                  id: "1-0-1-0",
                },
                {
                  id: "1-0-1-0",
                  type: "text",
                  valKey: "value",
                  key: "Statement by director responsible for the business responsibility report, highlighting ESG related challenges, targets and achievements (listed entity has flexibility regarding the placement of the disclosure",
                },
              ],
              [
                {
                  type: "static",
                  value: 2,
                  id: "1-0-1-1",
                },
                {
                  type: "static",
                  value:
                    "Details of the highest authority responsible for implementation and oversight of the buisness responsibility policy (ies.)",
                  id: "1-0-1-1",
                },
                {
                  id: "1-0-1-1",
                  type: "text",
                  valKey: "value",
                  key: "Details of the highest authority responsible for implementation and oversight of the buisness responsibility policy (ies.)",
                },
              ],
              [
                {
                  type: "static",
                  value: 3,
                  id: "1-0-1-2",
                },
                {
                  type: "static",
                  value:
                    "Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability related issues? (Yes / No). Ifyes, provide details.",
                  id: "1-0-1-2",
                },
                {
                  id: "1-0-1-2",
                  type: "text",
                  valKey: "value",
                  key: "Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability related issues? (Yes / No). Ifyes, provide details.",
                },
              ],
            ],
          },
        ],
      },
      {
        type: "accordion",
        label: "Details of Review of NGRBCs by the Company",
        tables: [
          {
            label: "",
            th: [
              "SR",
              "Subject for Review",
              "P1",
              "P2",
              "P3",
              "P4",
              "P5",
              "P6",
              "P7",
              "P8",
              "P9",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "i",
                  id: "1-1-0-0",
                },
                {
                  type: "static",
                  value:
                    "Performance against above policies and follow up action",
                  id: "1-1-0-0",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Performance against above policies and follow up action",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Performance against above policies and follow up action",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Performance against above policies and follow up action",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Performance against above policies and follow up action",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Performance against above policies and follow up action",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Performance against above policies and follow up action",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Performance against above policies and follow up action",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Performance against above policies and follow up action",
                },
                {
                  id: "1-1-0-0",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Performance against above policies and follow up action",
                },
              ],
              [
                {
                  type: "static",
                  value: "ii",
                  id: "1-1-0-1",
                },
                {
                  type: "static",
                  value:
                    "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                  id: "1-1-0-1",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P2",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P3",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P4",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P5",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P6",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P7",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P8",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
                {
                  id: "1-1-0-1",
                  type: "checkbox",
                  valKey: "P9",
                  key: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliance",
                },
              ],
              [
                {
                  type: "static",
                  value: "iii",
                  id: "1-1-0-2",
                },
                {
                  type: "static",
                  value:
                    "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
                {
                  id: "1-1-0-2",
                  type: "checkbox",
                  valKey: "P1",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                },
                {
                  type: "checkbox",
                  valKey: "P2",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P3",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P4",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P5",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P6",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P7",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P8",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P9",
                  key: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.",
                  id: "1-1-0-2",
                },
              ],
            ],
          },
        ],
      },
      {
        type: "accordion",
        label:
          "If answer to question (1) above is “No” i.e. not all Principles are covered by a policy, reasons to be stated",
        tables: [
          {
            label: "",
            th: ["SR", "Questions", "P1", "P2", "P3", "P4", "P5", "P6"],
            tr: [
              [
                {
                  type: "static",
                  value: "i",
                  id: "1-2-0-0",
                },
                {
                  type: "static",
                  value:
                    "The entity does not consider the Principles material to its business (Yes/No)",
                  id: "1-2-0-0",
                },
                {
                  type: "checkbox",
                  valKey: "P1",
                  key: "The entity does not consider the Principles material to its business (Yes/No)",
                  id: "1-2-0-0",
                },
                {
                  type: "checkbox",
                  valKey: "P2",
                  key: "The entity does not consider the Principles material to its business (Yes/No)",
                  id: "1-2-0-0",
                },
                {
                  type: "checkbox",
                  valKey: "P3",
                  key: "The entity does not consider the Principles material to its business (Yes/No)",
                  id: "1-2-0-0",
                },
                {
                  type: "checkbox",
                  valKey: "P4",
                  key: "The entity does not consider the Principles material to its business (Yes/No)",
                  id: "1-2-0-0",
                },
                {
                  type: "checkbox",
                  valKey: "P5",
                  key: "The entity does not consider the Principles material to its business (Yes/No)",
                  id: "1-2-0-0",
                },
                {
                  type: "checkbox",
                  valKey: "P6",
                  key: "The entity does not consider the Principles material to its business (Yes/No)",
                  id: "1-2-0-0",
                },
              ],
              [
                {
                  type: "static",
                  value: "ii",
                  id: "1-2-0-1",
                },
                {
                  type: "static",
                  value:
                    "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                  id: "1-2-0-1",
                },
                {
                  type: "checkbox",
                  valKey: "P1",
                  key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                  id: "1-2-0-1",
                },
                {
                  type: "checkbox",
                  valKey: "P2",
                  key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                  id: "1-2-0-1",
                },
                {
                  type: "checkbox",
                  valKey: "P3",
                  key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                  id: "1-2-0-1",
                },
                {
                  type: "checkbox",
                  valKey: "P4",
                  key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                  id: "1-2-0-1",
                },
                {
                  type: "checkbox",
                  valKey: "P5",
                  key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                  id: "1-2-0-1",
                },
                {
                  type: "checkbox",
                  valKey: "P6",
                  key: "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)",
                  id: "1-2-0-1",
                },
              ],
              [
                {
                  type: "static",
                  value: "iii",
                  id: "1-2-0-2",
                },
                {
                  type: "static",
                  value:
                    "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                  id: "1-2-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P1",
                  key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                  id: "1-2-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P2",
                  key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                  id: "1-2-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P3",
                  key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                  id: "1-2-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P4",
                  key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                  id: "1-2-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P5",
                  key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                  id: "1-2-0-2",
                },
                {
                  type: "checkbox",
                  valKey: "P6",
                  key: "The entity does not have the financial or/human and technical resources available for the task (Yes/No)",
                  id: "1-2-0-2",
                },
              ],
              [
                {
                  type: "static",
                  value: "iv",
                  id: "1-2-0-3",
                },
                {
                  type: "static",
                  value:
                    "It is planned to be done in the next financial year (Yes/No)",
                  id: "1-2-0-3",
                },
                {
                  type: "checkbox",
                  valKey: "P1",
                  key: "It is planned to be done in the next financial year (Yes/No)",
                  id: "1-2-0-3",
                },
                {
                  type: "checkbox",
                  valKey: "P2",
                  key: "It is planned to be done in the next financial year (Yes/No)",
                  id: "1-2-0-3",
                },
                {
                  type: "checkbox",
                  valKey: "P3",
                  key: "It is planned to be done in the next financial year (Yes/No)",
                  id: "1-2-0-3",
                },
                {
                  type: "checkbox",
                  valKey: "P4",
                  key: "It is planned to be done in the next financial year (Yes/No)",
                  id: "1-2-0-3",
                },
                {
                  type: "checkbox",
                  valKey: "P5",
                  key: "It is planned to be done in the next financial year (Yes/No)",
                  id: "1-2-0-3",
                },
                {
                  type: "checkbox",
                  valKey: "P6",
                  key: "It is planned to be done in the next financial year (Yes/No)",
                  id: "1-2-0-3",
                },
              ],
              [
                {
                  type: "static",
                  value: "v",
                  id: "1-2-0-4",
                },
                {
                  type: "static",
                  value: "Any other reason (please specify)",
                  id: "1-2-0-4",
                },
                {
                  type: "checkbox",
                  valKey: "P1",
                  key: "Any other reason (please specify)",
                  id: "1-2-0-4",
                },
                {
                  type: "checkbox",
                  valKey: "P2",
                  key: "Any other reason (please specify)",
                  id: "1-2-0-4",
                },
                {
                  type: "checkbox",
                  valKey: "P3",
                  key: "Any other reason (please specify)",
                  id: "1-2-0-4",
                },
                {
                  type: "checkbox",
                  valKey: "P4",
                  key: "Any other reason (please specify)",
                  id: "1-2-0-4",
                },
                {
                  type: "checkbox",
                  valKey: "P5",
                  key: "Any other reason (please specify)",
                  id: "1-2-0-4",
                },
                {
                  type: "checkbox",
                  valKey: "P6",
                  key: "Any other reason (please specify)",
                  id: "1-2-0-4",
                },
              ],
            ],
          },
        ],
      },
      {
        type: "accordion",
        label: "PRINCIPLE WISE PERFORMANCE DISCLOSURE",
        tables: [
          {
            label: "",
            th: ["SR", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "1-3-0-0",
                },
                {
                  type: "static",
                  value:
                    "This section is aimed at helping entities demonstrate their performance in integrating the Principles and Core Elements with key processes and decisions. The information sought is categorized as “Essential” and “Leadership”. While the essential indicators are expected to be disclosed by every entity that is mandated to file this report, the leadership indicators may be voluntarily disclosed by entities which aspire to progress to a higher level in their quest to be socially, environmentally and ethically responsible.",
                  id: "1-3-0-0",
                },
                {
                  id: "1-3-0-0",
                  type: "textarea",
                  valKey: "Value",
                  key: "This section is aimed at helping entities demonstrate their performance in integrating the Principles and Core Elements with key processes and decisions. The information sought is categorized as “Essential” and “Leadership”. While the essential indicators are expected to be disclosed by every entity that is mandated to file this report, the leadership indicators may be voluntarily disclosed by entities which aspire to progress to a higher level in their quest to be socially, environmentally and ethically responsible.",
                },
              ],
            ],
          },
        ],
      },
    ],
    Principle1: [
      {
        type: "accordion",
        label:
          "Businesses should conduct and govern themselves with integrity, and in a manner that is Ethical, Transparent and Accountable.",
        tables: [
          {
            label:
              "Percentage coverage by training and awareness programmes on any of the Principles during the financial year",
            th: [
              "Sr",
              "Segments",
              "Total number of training and awareness programmes held",
              "Topics / principles covered under the training and its impac",
              "%age of persons in respective category covered by the awareness programmes",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "2-0-0-0",
                },
                {
                  type: "static",
                  value: "Board of Directors",
                  id: "2-0-0-0",
                },
                {
                  type: "text",
                  valKey:
                    "Total number of training and awareness programmes held",
                  key: "Board of Directors",
                  id: "2-0-0-0",
                },
                {
                  type: "text",
                  valKey:
                    "Topics / principles covered under the training and its impac",
                  key: "Board of Directors",
                  id: "2-0-0-0",
                },
                {
                  type: "text",
                  valKey:
                    "%age of persons in respective category covered by the awareness programmes",
                  key: "Board of Directors",
                  id: "2-0-0-0",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "2-0-0-1",
                },
                {
                  type: "static",
                  value: "Key Managerial Personnel",
                  id: "2-0-0-1",
                },
                {
                  type: "text",
                  valKey:
                    "Total number of training and awareness programmes held",
                  key: "Key Managerial Personnel",
                  id: "2-0-0-1",
                },
                {
                  type: "text",
                  valKey:
                    "Topics / principles covered under the training and its impac",
                  key: "Key Managerial Personnel",
                  id: "2-0-0-1",
                },
                {
                  type: "text",
                  valKey:
                    "%age of persons in respective category covered by the awareness programmes",
                  key: "Key Managerial Personnel",
                  id: "2-0-0-1",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "2-0-0-2",
                },
                {
                  type: "static",
                  value: "Employees other than BoD and KMPs",
                  id: "2-0-0-2",
                },
                {
                  type: "text",
                  valKey:
                    "Total number of training and awareness programmes held",
                  key: "Employees other than BoD and KMPs",
                  id: "2-0-0-2",
                },
                {
                  type: "text",
                  valKey:
                    "Topics / principles covered under the training and its impac",
                  key: "Employees other than BoD and KMPs",
                  id: "2-0-0-2",
                },
                {
                  type: "text",
                  valKey:
                    "%age of persons in respective category covered by the awareness programmes",
                  key: "Employees other than BoD and KMPs",
                  id: "2-0-0-2",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "2-0-0-3",
                },
                {
                  type: "static",
                  value: "Workers",
                  id: "2-0-0-3",
                },
                {
                  type: "text",
                  valKey:
                    "Total number of training and awareness programmes held",
                  key: "Workers",
                  id: "2-0-0-3",
                },
                {
                  type: "text",
                  valKey:
                    "Topics / principles covered under the training and its impac",
                  key: "Workers",
                  id: "2-0-0-3",
                },
                {
                  type: "text",
                  valKey:
                    "%age of persons in respective category covered by the awareness programmes",
                  key: "Workers",
                  id: "2-0-0-3",
                },
              ],
            ],
          },
          {
            label:
              "Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount paid in proceedings (by the entity or by directors / KMPs) with regulators/ law enforcement agencies/ judicial institutions, in the financial year, in the following format (Note: the entity shall make disclosures on the basis of materiality as specified in Regulation 30 of SEBI (Listing Obligations and Disclosure Obligations) Regulations, 2015 and as disclosed on the entitys website",
            th: [
              "Sr",
              "Segments",
              "NGRBC Principle",
              "Name of the regulatory/ enforcement agencies/ judicial institutions",
              "Preffered? (Yes/No)",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "Monetary",
                  colSpan: 5,
                  id: "2-0-1-0",
                },
              ],
              [
                {
                  type: "static",
                  value: "a",
                  id: "2-0-1-1",
                },
                {
                  type: "static",
                  value: "Penality fine",
                  id: "2-0-1-1",
                },
                {
                  id: "2-0-1-1",
                  type: "text",
                  valKey: "NGRBC Principle",
                  key: "Penality fine",
                },
                {
                  id: "2-0-1-1",
                  type: "text",
                  valKey:
                    "Name of the regulatory/ enforcement agencies/ judicial institutions",
                  key: "Penality fine",
                },
                {
                  id: "2-0-1-1",
                  type: "text",
                  valKey: "Preffered? (Yes/No)",
                  key: "Penality fine",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "2-0-1-2",
                },
                {
                  type: "static",
                  value: "Settlement",
                  id: "2-0-1-2",
                },
                {
                  id: "2-0-1-2",
                  type: "text",
                  valKey: "NGRBC Principle",
                  key: "Settlement",
                },
                {
                  id: "2-0-1-2",
                  type: "text",
                  valKey:
                    "Name of the regulatory/ enforcement agencies/ judicial institutions",
                  key: "Settlement",
                },
                {
                  id: "2-0-1-2",
                  type: "text",
                  valKey: "Preffered? (Yes/No)",
                  key: "Settlement",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "2-0-1-3",
                },
                {
                  type: "static",
                  value: "Compunding Fee",
                  id: "2-0-1-3",
                },
                {
                  id: "2-0-1-3",
                  type: "text",
                  valKey: "NGRBC Principle",
                  key: "Compunding Fee",
                },
                {
                  id: "2-0-1-3",
                  type: "text",
                  valKey:
                    "Name of the regulatory/ enforcement agencies/ judicial institutions",
                  key: "Compunding Fee",
                },
                {
                  id: "2-0-1-3",
                  type: "text",
                  valKey: "Preffered? (Yes/No)",
                  key: "Compunding Fee",
                },
              ],
              [
                {
                  type: "static",
                  value: "Non-Monetary",
                  colSpan: 5,
                  id: "2-0-1-4",
                },
              ],
              [
                {
                  type: "static",
                  value: "a",
                  id: "2-0-1-5",
                },
                {
                  type: "static",
                  value: "Imprisonment",
                  id: "2-0-1-5",
                },
                {
                  id: "2-0-1-5",
                  type: "text",
                  valKey: "NGRBC Principle",
                  key: "Imprisonment",
                },
                {
                  id: "2-0-1-5",
                  type: "text",
                  valKey:
                    "Name of the regulatory/ enforcement agencies/ judicial institutions",
                  key: "Imprisonment",
                },
                {
                  id: "2-0-1-5",
                  type: "text",
                  valKey: "Preffered? (Yes/No)",
                  key: "Imprisonment",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "2-0-1-6",
                },
                {
                  type: "static",
                  value: "Punishement",
                  id: "2-0-1-6",
                },
                {
                  id: "2-0-1-6",
                  type: "text",
                  valKey: "NGRBC Principle",
                  key: "Punishement",
                },
                {
                  id: "2-0-1-6",
                  type: "text",
                  valKey:
                    "Name of the regulatory/ enforcement agencies/ judicial institutions",
                  key: "Punishement",
                },
                {
                  id: "2-0-1-6",
                  type: "text",
                  valKey: "Preffered? (Yes/No)",
                  key: "Punishement",
                },
              ],
            ],
          },
          {
            label:
              "Awareness programmes conducted for value chain partners on any of the Principles during the financial year",
            th: ["Sr", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "2-0-2-0",
                },
                {
                  type: "static",
                  value: "Total number of awareness programmes held",
                  id: "2-0-2-0",
                },
                {
                  type: "text",
                  valkey: "value",
                  key: "Total number of awareness programmes held",
                  id: "2-0-2-0",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "2-0-2-1",
                },
                {
                  type: "static",
                  value: "Topics / principles covered under the training",
                  id: "2-0-2-1",
                },
                {
                  id: "2-0-2-1",
                  type: "text",
                  valkey: "value",
                  key: "Topics / principles covered under the training",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "2-0-2-2",
                },
                {
                  type: "static",
                  value:
                    "%age of value chain partners covered (by value of business done with such partners) under the awareness programmes",
                  id: "2-0-2-2",
                },
                {
                  id: "2-0-2-2",
                  type: "text",
                  valkey: "value",
                  key: "%age of value chain partners covered (by value of business done with such partners) under the awareness programmes",
                },
              ],
            ],
          },
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value: "4",
                  id: "2-0-3-0",
                },
                {
                  type: "static",
                  value:
                    "Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board?",
                  id: "2-0-3-0",
                },
                {
                  id: "2-0-3-0",
                  type: "text",
                  valKey: "value",
                  key: "Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board?",
                },
              ],
            ],
          },
        ],
      },
    ],
    Principle2: [
      {
        type: "accordion",
        label:
          "Businesses should provide goods and services in a manner that is sustainable and safe",
        tables: [
          {
            label:
              "Percentage of R&D and capital expenditure (capex) investments in specific technologies to improve the environmental and social impacts of product and processes to total R&D and capex investments made by the entity, respectively",
            th: [
              "Sr",
              "Title",
              "Current Financial Year",
              "Details of improvements in environmental and social impacts",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "3-0-0-0",
                },
                {
                  type: "static",
                  value: "R&D",
                  id: "3-0-0-0",
                },
                {
                  id: "3-0-0-0",
                  type: "text",
                  valKey: "Current Financial Year",
                  key: "R&D",
                },
                {
                  id: "3-0-0-0",
                  type: "text",
                  valKey:
                    "Details of improvements in environmental and social impacts",
                  key: "R&D",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "3-0-0-1",
                },
                {
                  type: "static",
                  value: "Capex",
                  id: "3-0-0-1",
                },
                {
                  type: "text",
                  valKey: "Current Financial Year",
                  key: "Capex",
                  id: "3-0-0-1",
                },
                {
                  type: "text",
                  valKey:
                    "Details of improvements in environmental and social impacts",
                  key: "Capex",
                  id: "3-0-0-1",
                },
              ],
            ],
          },
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:
                    "Does the entity have procedures in place for sustainable sourcing? If yes, what percentage of inputs were sourced sustainably?",
                  id: "3-0-1-0",
                },
                {
                  id: "3-0-1-0",
                  type: "text",
                  valKey: "value",
                  key: "Does the entity have procedures in place for sustainable sourcing? If yes, what percentage of inputs were sourced sustainably?",
                },
              ],
            ],
          },
          {
            label:
              "Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for manufacturing industry) or for its services (for service industry)? If yes, provide details in the following format?",
            th: ["Sr", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "3-0-2-0",
                },
                {
                  type: "static",
                  value: "NIC Code",
                  id: "3-0-2-0",
                },
                {
                  id: "3-0-2-0",
                  type: "text",
                  valKey: "value",
                  key: "NIC Code",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "3-0-2-1",
                },
                {
                  type: "static",
                  value: "Name of Product /Service",
                  id: "3-0-2-1",
                },
                {
                  id: "3-0-2-1",
                  type: "text",
                  valKey: "value",
                  key: "Name of Product /Service",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "3-0-2-2",
                },
                {
                  type: "static",
                  value: "% of total Turnover contributed",
                  id: "3-0-2-2",
                },
                {
                  id: "3-0-2-2",
                  type: "text",
                  valKey: "value",
                  key: "% of total Turnover contributed",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "3-0-2-3",
                },
                {
                  type: "static",
                  value:
                    "Boundary for which the Life Cycle Perspective / Assessment was conducted",
                  id: "3-0-2-3",
                },
                {
                  id: "3-0-2-3",
                  type: "text",
                  valKey: "value",
                  key: "Boundary for which the Life Cycle Perspective / Assessment was conducted",
                },
              ],
              [
                {
                  type: "static",
                  value: "e",
                  id: "3-0-2-4",
                },
                {
                  type: "static",
                  value:
                    "Whether conducted by independent external agency (Yes/No)",
                  id: "3-0-2-4",
                },
                {
                  id: "3-0-2-4",
                  type: "text",
                  valKey: "value",
                  key: "Whether conducted by independent external agency (Yes/No)",
                },
              ],
              [
                {
                  type: "static",
                  value: "f",
                  id: "3-0-2-5",
                },
                {
                  type: "static",
                  value:
                    "Results communicated in public domain (Yes/No) If yes, provide the web-link.",
                  id: "3-0-2-5",
                },
                {
                  id: "3-0-2-5",
                  type: "text",
                  valKey: "value",
                  key: "Results communicated in public domain (Yes/No) If yes, provide the web-link.",
                },
              ],
            ],
          },
          {
            label:
              "Reclaimed products and their packaging materials (as percentage of products sold) for each product category.",
            th: ["Sr", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "3-0-3-0",
                },
                {
                  type: "static",
                  value: "Indicate product category",
                  id: "3-0-3-0",
                },
                {
                  id: "3-0-3-0",
                  type: "text",
                  valKey: "value",
                  key: "Indicate product category",
                },
              ],
              [
                {
                  type: "static",
                  value: "a",
                  id: "3-0-3-1",
                },
                {
                  type: "static",
                  value:
                    "Reclaimed products and their packaging materials as % of total products sold in respective category",
                  id: "3-0-3-1",
                },
                {
                  id: "3-0-3-1",
                  type: "text",
                  valKey: "value",
                  key: "Reclaimed products and their packaging materials as % of total products sold in respective category",
                },
              ],
            ],
          },
        ],
      },
    ],
    Principle3: [
      {
        type: "accordion",
        label:
          "Businesses should respect and promote the well-being of all employees, including those in their value chains",
        tables: [
          {
            label:
              "Is there a mechanism available to receive and redress grievances for the following categories of employees and worker? If yes, give details of the mechanism in brief",
            th: [
              "Sr",
              "Parameters",
              "Yes/NO",
              "IF yes then give the details of mechanism in details",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "4-0-0-0",
                },
                {
                  type: "static",
                  value: "Employees ( Permanent + Contract)",
                  id: "4-0-0-0",
                },
                {
                  id: "4-0-0-0",
                  type: "text",
                  valKey: "Yes/NO",
                  key: "Employees ( Permanent + Contract)",
                },
                {
                  id: "4-0-0-0",
                  type: "text",
                  valKey: "IF yes then give the details of mechanism in details",
                  key: "Employees ( Permanent + Contract)",
                },
              ],
              [
                {
                  type: "static",
                  value: "a",
                  id: "4-0-0-1",
                },
                {
                  type: "static",
                  value: "Workers ( Permanent + Contract)",
                  id: "4-0-0-1",
                },
                {
                  id: "4-0-0-1",
                  type: "text",
                  valKey: "Yes/NO",
                  key: "Workers ( Permanent + Contract)",
                },
                {
                  id: "4-0-0-1",
                  type: "text",
                  valKey: "IF yes then give the details of mechanism in details",
                  key: "Workers ( Permanent + Contract)",
                },
              ],
            ],
          },
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:
                    "Does the entity extend any life insurance or any compensatory package in the event of death of (A) Employees (Y/N) (B) Workers (Y/N).f",
                  id: "4-0-1-0",
                },
                {
                  id: "4-0-1-0",
                  type: "text",
                  valKey: "value",
                  key: "Does the entity extend any life insurance or any compensatory package in the event of death of (A) Employees (Y/N) (B) Workers (Y/N).f",
                },
              ],
              [
                {
                  type: "static",
                  value:
                    "Provide details of any corrective actions taken or underway to address significant risks / concerns arising from assessments of health and safety practices and working conditions of value chain partners",
                  id: "4-0-1-1",
                },
                {
                  id: "4-0-1-1",
                  type: "text",
                  valKey: "value",
                  key: "Provide details of any corrective actions taken or underway to address significant risks / concerns arising from assessments of health and safety practices and working conditions of value chain partners",
                },
              ],
            ],
          },
        ],
      },
    ],
    Principle4: [
      {
        type: "accordion",
        label:
          "Businesses should respect the interests of and be responsive to all its stakeholders",
        tables: [
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:
                    "Describe the processes for identifying key stakeholder groups of the entity",
                  id: "5-0-0-0",
                },
                {
                  id: "5-0-0-0",
                  type: "text",
                  valKey: "value",
                  key: "Describe the processes for identifying key stakeholder groups of the entity",
                },
              ],
            ],
          },
          {
            label:
              "List stakeholder groups identified as key for your entity and the frequency of engagement with each stakeholder group",
            th: ["Sr", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "5-0-1-0",
                },
                {
                  type: "static",
                  value: "Stakeholder Group",
                  id: "5-0-1-0",
                },
                {
                  id: "5-0-1-0",
                  type: "text",
                  valKey: "value",
                  key: "Stakeholder Group",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "5-0-1-1",
                },
                {
                  type: "static",
                  value:
                    "Whether identified as Vulnerable & Marginalized Group (Yes/No)",
                  id: "5-0-1-1",
                },
                {
                  id: "5-0-1-1",
                  type: "text",
                  valKey: "value",
                  key: "Whether identified as Vulnerable & Marginalized Group (Yes/No)",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "5-0-1-2",
                },
                {
                  type: "static",
                  value: "Channels of communication",
                  id: "5-0-1-2",
                },
                {
                  id: "5-0-1-2",
                  type: "text",
                  valKey: "value",
                  key: "Channels of communication",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "5-0-1-3",
                },
                {
                  type: "static",
                  value:
                    "Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify)",
                  id: "5-0-1-3",
                },
                {
                  id: "5-0-1-3",
                  type: "text",
                  valKey: "value",
                  key: "Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify)",
                },
              ],
              [
                {
                  type: "static",
                  value: "e",
                  id: "5-0-1-4",
                },
                {
                  type: "static",
                  value:
                    "Purpose and scope of engagement including key topics and concerns raised during such engagement",
                  id: "5-0-1-4",
                },
                {
                  id: "5-0-1-4",
                  type: "text",
                  valKey: "value",
                  key: "Purpose and scope of engagement including key topics and concerns raised during such engagement",
                },
              ],
            ],
          },
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:
                    "Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated, how is feedback from such consultations provided to the Board",
                  id: "5-0-2-0",
                },
                {
                  id: "5-0-2-0",
                  type: "text",
                  valKey: "value",
                  key: "Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated, how is feedback from such consultations provided to the Board",
                },
              ],
              [
                {
                  type: "static",
                  value:
                    "Whether stakeholder consultation is used to support the identification and management of environmental, and social topics (Yes / No). If so, provide details of instances as to how the inputs received from stakeholders on these topics were incorporated into policies and activities of the entity",
                  id: "5-0-2-1",
                },
                {
                  id: "5-0-2-1",
                  type: "text",
                  valKey: "value",
                  key: "Whether stakeholder consultation is used to support the identification and management of environmental, and social topics (Yes / No). If so, provide details of instances as to how the inputs received from stakeholders on these topics were incorporated into policies and activities of the entity",
                },
              ],
              [
                {
                  type: "static",
                  value:
                    "Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups.",
                  id: "5-0-2-2",
                },
                {
                  id: "5-0-2-2",
                  type: "text",
                  valKey: "value",
                  key: "Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups.",
                },
              ],
            ],
          },
        ],
      },
    ],
    Principle5: [
      {
        label: "Businesses should respect and promote human rights",
        type: "accordion",
        tables: [
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:
                    "Do you have a focal point (Individual/ Committee) responsible for addressing human rights impacts or issues caused or contributed to by the business? (Yes/No)",
                  id: "6-0-0-0",
                },
                {
                  id: "6-0-0-0",
                  type: "text",
                  valKey: "value",
                  key: "Do you have a focal point (Individual/ Committee) responsible for addressing human rights impacts or issues caused or contributed to by the business? (Yes/No)",
                },
              ],
              [
                {
                  type: "static",
                  value:
                    "Describe the internal mechanisms in place to redress grievances related to human rights issues.",
                  id: "6-0-0-1",
                },
                {
                  id: "6-0-0-1",
                  type: "text",
                  valKey: "value",
                  key: "Describe the internal mechanisms in place to redress grievances related to human rights issues.",
                },
              ],
            ],
          },
          {
            label:
              "Number of Complaints on the following made by employees and workers:",
            th: [
              "Sr",
              "Title",
              "Filed during the year",
              "Pending resolution at the end of year",
              "Remarks",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "6-0-1-0",
                },
                {
                  type: "static",
                  value: "Sexual Harassment",
                  id: "6-0-1-0",
                },
                {
                  id: "6-0-1-0",
                  type: "text",
                  valKey: "Filed during the year",
                  key: "Sexual Harassment",
                },
                {
                  id: "6-0-1-0",
                  type: "text",
                  valKey: "Pending resolution at the end of year",
                  key: "Sexual Harassment",
                },
                {
                  id: "6-0-1-0",
                  type: "text",
                  valKey: "Remarks",
                  key: "Sexual Harassment",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "6-0-1-1",
                },
                {
                  type: "static",
                  value: "Discrimination at workplace",
                  id: "6-0-1-1",
                },
                {
                  id: "6-0-1-1",
                  type: "text",
                  valKey: "Filed during the year",
                  key: "Discrimination at workplace",
                },
                {
                  id: "6-0-1-1",
                  type: "text",
                  valKey: "Pending resolution at the end of year",
                  key: "Discrimination at workplace",
                },
                {
                  id: "6-0-1-1",
                  type: "text",
                  valKey: "Remarks",
                  key: "Discrimination at workplace",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "6-0-1-2",
                },
                {
                  type: "static",
                  value: "Child Labour",
                  id: "6-0-1-2",
                },
                {
                  id: "6-0-1-2",
                  type: "text",
                  valKey: "Filed during the year",
                  key: "Child Labour",
                },
                {
                  id: "6-0-1-2",
                  type: "text",
                  valKey: "Pending resolution at the end of year",
                  key: "Child Labour",
                },
                {
                  id: "6-0-1-2",
                  type: "text",
                  valKey: "Remarks",
                  key: "Child Labour",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "6-0-1-3",
                },
                {
                  type: "static",
                  value: "Forced Labour/Involuntary Labour",
                  id: "6-0-1-3",
                },
                {
                  id: "6-0-1-3",
                  type: "text",
                  valKey: "Filed during the year",
                  key: "Forced Labour/Involuntary Labour",
                },
                {
                  id: "6-0-1-3",
                  type: "text",
                  valKey: "Pending resolution at the end of year",
                  key: "Forced Labour/Involuntary Labour",
                },
                {
                  id: "6-0-1-3",
                  type: "text",
                  valKey: "Remarks",
                  key: "Forced Labour/Involuntary Labour",
                },
              ],
              [
                {
                  type: "static",
                  value: "e",
                  id: "6-0-1-4",
                },
                {
                  type: "static",
                  value: "Wages",
                  id: "6-0-1-4",
                },
                {
                  id: "6-0-1-4",
                  type: "text",
                  valKey: "Filed during the year",
                  key: "Wages",
                },
                {
                  id: "6-0-1-4",
                  type: "text",
                  valKey: "Pending resolution at the end of year",
                  key: "Wages",
                },
                {
                  id: "6-0-1-4",
                  type: "text",
                  valKey: "Remarks",
                  key: "Wages",
                },
              ],
              [
                {
                  type: "static",
                  value: "f",
                  id: "6-0-1-5",
                },
                {
                  type: "static",
                  value: "Other human",
                  id: "6-0-1-5",
                },
                {
                  id: "6-0-1-5",
                  type: "text",
                  valKey: "Filed during the year",
                  key: "Other human",
                },
                {
                  id: "6-0-1-5",
                  type: "text",
                  valKey: "Pending resolution at the end of year",
                  key: "Other human",
                },
                {
                  id: "6-0-1-5",
                  type: "text",
                  valKey: "Remarks",
                  key: "Other human",
                },
              ],
              [
                {
                  type: "static",
                  value: "g",
                  id: "6-0-1-6",
                },
                {
                  type: "static",
                  value: "Rights related issues",
                  id: "6-0-1-6",
                },
                {
                  id: "6-0-1-6",
                  type: "text",
                  valKey: "Filed during the year",
                  key: "Rights related issues",
                },
                {
                  id: "6-0-1-6",
                  type: "text",
                  valKey: "Pending resolution at the end of year",
                  key: "Rights related issues",
                },
                {
                  id: "6-0-1-6",
                  type: "text",
                  valKey: "Remarks",
                  key: "Rights related issues",
                },
              ],
            ],
          },
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:
                    "Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints",
                  id: "6-0-2-0",
                },
                {
                  id: "6-0-2-0",
                  type: "text",
                  valKey: "value",
                  key: "Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints",
                },
              ],
              [
                {
                  type: "static",
                  value:
                    " Details of the scope and coverage of any Human rights due-diligence conducted.",
                  id: "6-0-2-1",
                },
                {
                  id: "6-0-2-1",
                  type: "text",
                  valKey: "value",
                  key: " Details of the scope and coverage of any Human rights due-diligence conducted.",
                },
              ],
              [
                {
                  type: "static",
                  value:
                    "Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the Rights of Persons with Disabilities Act, 2016?",
                  id: "6-0-2-2",
                },
                {
                  id: "6-0-2-2",
                  type: "text",
                  valKey: "value",
                  key: "Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the Rights of Persons with Disabilities Act, 2016?",
                },
              ],
            ],
          },
          {
            label: "Details on assessment of value chain partners",
            th: [
              "Sr",
              "Categories",
              "% of value chain partners (by value of business done with such partners) that were assessed",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "6-0-3-0",
                },
                {
                  type: "static",
                  value: "Sexual Harassment",
                  id: "6-0-3-0",
                },
                {
                  id: "6-0-3-0",
                  type: "text",
                  valKey:
                    "% of value chain partners (by value of business done with such partners) that were assessed",
                  key: "Sexual Harassment",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "6-0-3-1",
                },
                {
                  type: "static",
                  value: "Discrimination at workplace",
                  id: "6-0-3-1",
                },
                {
                  id: "6-0-3-1",
                  type: "text",
                  valKey:
                    "% of value chain partners (by value of business done with such partners) that were assessed",
                  key: "Discrimination at workplace",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "6-0-3-2",
                },
                {
                  type: "static",
                  value: "Child Labour",
                  id: "6-0-3-2",
                },
                {
                  id: "6-0-3-2",
                  type: "text",
                  valKey:
                    "% of value chain partners (by value of business done with such partners) that were assessed",
                  key: "Child Labour",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "6-0-3-3",
                },
                {
                  type: "static",
                  value: "Forced Labour/Involuntary Labour",
                  id: "6-0-3-3",
                },
                {
                  id: "6-0-3-3",
                  type: "text",
                  valKey:
                    "% of value chain partners (by value of business done with such partners) that were assessed",
                  key: "Forced Labour/Involuntary Labour",
                },
              ],
              [
                {
                  type: "static",
                  value: "e",
                  id: "6-0-3-4",
                },
                {
                  type: "static",
                  value: "Wages",
                  id: "6-0-3-4",
                },
                {
                  id: "6-0-3-4",
                  type: "text",
                  valKey:
                    "% of value chain partners (by value of business done with such partners) that were assessed",
                  key: "Wages",
                },
              ],
              [
                {
                  type: "static",
                  value: "f",
                  id: "6-0-3-5",
                },
                {
                  type: "static",
                  value: "Others – please specify",
                  id: "6-0-3-5",
                },
                {
                  id: "6-0-3-5",
                  type: "text",
                  valKey:
                    "% of value chain partners (by value of business done with such partners) that were assessed",
                  key: "Others – please specify",
                },
              ],
              [
                {
                  type: "static",
                  value: "g",
                  id: "6-0-3-6",
                },
                {
                  type: "static",
                  value: "Any Corrective Action ?",
                  id: "6-0-3-6",
                },
                {
                  id: "6-0-3-6",
                  type: "text",
                  valKey:
                    "% of value chain partners (by value of business done with such partners) that were assessed",
                  key: "Any Corrective Action ?",
                },
              ],
            ],
          },
        ],
      },
    ],
    Principle7: [
      {
        type: "accordion",
        label:
          "Businesses, when engaging in influencing public and regulatory policy, should do so in a manner that is responsible and transparent",
        tables: [
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:"Number of affiliations with trade and industry chambers/ associations.",
                  id: "8-0-0-0",
                },
                {
                  type: "text",
                  valKey: "value",
                  key: "Number of affiliations with trade and industry chambers/ associations.",
                  id: "8-0-0-0",
                },
              ],
            ],
          },
          {
            label:
              "List the top 10 trade and industry chambers/ associations (determined based on the total members of such body) the entity is a member of/ affiliated to.",
            th: ["Sr", "Title", "A", "B", "C", "D", "E", "F", "G"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "8-0-1-0",
                },
                {
                  type: "static",
                  value: "Name of the trade and industry chambers/ associations",
                  id: "8-0-1-0",
                },
                {
                  id: "8-0-1-0",
                  type: "text",
                  valKey: "A",
                  key: "Name of the trade and industry chambers/ associations",
                },
                {
                  id: "8-0-1-0",
                  type: "text",
                  valKey: "B",
                  key: "Name of the trade and industry chambers/ associations",
                },
                {
                  id: "8-0-1-0",
                  type: "text",
                  valKey: "C",
                  key: "Name of the trade and industry chambers/ associations",
                },
                {
                  id: "8-0-1-0",
                  type: "text",
                  valKey: "D",
                  key: "Name of the trade and industry chambers/ associations",
                },
                {
                  id: "8-0-1-0",
                  type: "text",
                  valKey: "E",
                  key: "Name of the trade and industry chambers/ associations",
                },
                {
                  id: "8-0-1-0",
                  type: "text",
                  valKey: "F",
                  key: "Name of the trade and industry chambers/ associations",
                },
                {
                  id: "8-0-1-0",
                  type: "text",
                  valKey: "G",
                  key: "Name of the trade and industry chambers/ associations",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "8-0-1-1",
                },
                {
                  type: "static",
                  value:
                    "Reach of trade and industry chambers/ associations ( state & National)",
                  id: "8-0-1-1",
                },
                {
                  id: "8-0-1-1",
                  type: "text",
                  valKey: "A",
                  key: "Reach of trade and industry chambers/ associations ( state & National)",
                },
                {
                  id: "8-0-1-1",
                  type: "text",
                  valKey: "B",
                  key: "Reach of trade and industry chambers/ associations ( state & National)",
                },
                {
                  id: "8-0-1-1",
                  type: "text",
                  valKey: "C",
                  key: "Reach of trade and industry chambers/ associations ( state & National)",
                },
                {
                  id: "8-0-1-1",
                  type: "text",
                  valKey: "D",
                  key: "Reach of trade and industry chambers/ associations ( state & National)",
                },
                {
                  id: "8-0-1-1",
                  type: "text",
                  valKey: "E",
                  key: "Reach of trade and industry chambers/ associations ( state & National)",
                },
                {
                  id: "8-0-1-1",
                  type: "text",
                  valKey: "F",
                  key: "Reach of trade and industry chambers/ associations ( state & National)",
                },
                {
                  id: "8-0-1-1",
                  type: "text",
                  valKey: "G",
                  key: "Reach of trade and industry chambers/ associations ( state & National)",
                },
              ],
            ],
          },
          {
            label:
              "Provide details of corrective action taken or underway on any issues related to anti- competitive conduct by the entity, based on adverse orders from regulatory authorities.",
            th: ["Sr", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "8-0-2-0",
                },
                {
                  type: "static",
                  value: "Name of authority",
                  id: "8-0-2-0",
                },
                {
                  type: "text",
                  id: "8-0-2-0",
                  key: "Name of authority",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "8-0-2-1",
                },
                {
                  type: "static",
                  value: "Brief of the case",
                  id: "8-0-2-1",
                },
                {
                  type: "text",
                  id: "8-0-2-1",
                  key: "Brief of the case",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "8-0-2-2",
                },
                {
                  type: "static",
                  value: "Corrective action taken",
                  id: "8-0-2-2",
                },
                {
                  type: "text",
                  id: "8-0-2-2",
                  key: "Corrective action taken",
                  valKey: "value",
                },
              ],
            ],
          },
        ],
      },
    ],
    Principle8: [
      {
        type: "accordion",
        label:
          "Businesses should promote inclusive growth and equitable development",
        tables: [
          {
            label:
              "Details of Social Impact Assessments (SIA) of projects undertaken by the entity based on applicable laws, in the current financial year.",
            th: ["Sr", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "9-0-0-0",
                },
                {
                  type: "static",
                  value: "Name and brief details of project",
                  id: "9-0-0-0",
                },
                {
                  id: "9-0-0-0",
                  type: "text",
                  key: "Name and brief details of project",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "9-0-0-1",
                },
                {
                  type: "static",
                  value: "SIA Notification No.",
                  id: "9-0-0-1",
                },
                {
                  type: "text",
                  id: "9-0-0-1",
                  key: "SIA Notification No.",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "9-0-0-2",
                },
                {
                  type: "static",
                  value: "Date of notification",
                  id: "9-0-0-2",
                },
                {
                  type: "text",
                  id: "9-0-0-2",
                  key: "Date of notification",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "9-0-0-3",
                },
                {
                  type: "static",
                  value:
                    "Whether conducted by independent external agency (Yes / No)",
                  id: "9-0-0-3",
                },
                {
                  id: "9-0-0-3",
                  type: "text",
                  key: "Whether conducted by independent external agency (Yes / No)",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "e",
                  id: "9-0-0-4",
                },
                {
                  type: "static",
                  value: "Results communicated in public domain (Yes / No)",
                  id: "9-0-0-4",
                },
                {
                  id: "9-0-0-4",
                  type: "text",
                  key: "Results communicated in public domain (Yes / No)",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "f",
                  id: "9-0-0-5",
                },
                {
                  type: "static",
                  value: "Relevant Web link",
                  id: "9-0-0-5",
                },
                {
                  type: "text",
                  id: "9-0-0-5",
                  key: "Relevant Web link",
                  valKey: "value",
                },
              ],
            ],
          },
          {
            label:
              "Percentage of input material (inputs to total inputs by value) sourced from suppliers",
            th: ["Sr", "Title", "Current Financial Year"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "9-0-1-0",
                },
                {
                  type: "static",
                  value: "Directly sourced from MSMEs/ small producers",
                  id: "9-0-1-0",
                },
                {
                  id: "9-0-1-0",
                  type: "text",
                  key: "Directly sourced from MSMEs/ small producers",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "9-0-1-1",
                },
                {
                  type: "static",
                  value:
                    "Sourced directly from within the district and neighbouring districts",
                  id: "9-0-1-1",
                },
                {
                  id: "9-0-1-1",
                  type: "text",
                  key: "Sourced directly from within the district and neighbouring districts",
                  valKey: "value",
                },
              ],
            ],
          },
          {
            label:
              "Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge",
            th: ["Sr", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "9-0-2-0",
                },
                {
                  type: "static",
                  value: "Intellectual Property based on traditional knowledge",
                  id: "9-0-2-0",
                },
                {
                  id: "9-0-2-0",
                  type: "text",
                  key: "Intellectual Property based on traditional knowledge",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "9-0-2-1",
                },
                {
                  type: "static",
                  value: "Owned/ Acquired (Yes/No)",
                  id: "9-0-2-1",
                },
                {
                  id: "9-0-2-1",
                  type: "text",
                  key: "Owned/ Acquired (Yes/No)",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "9-0-2-2",
                },
                {
                  type: "static",
                  value: "Benefit shared (Yes / No)",
                  id: "9-0-2-2",
                },
                {
                  id: "9-0-2-2",
                  type: "text",
                  key: "Benefit shared (Yes / No)",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "9-0-2-3",
                },
                {
                  type: "static",
                  value: "Basis of calculating benefit share",
                  id: "9-0-2-3",
                },
                {
                  id: "9-0-2-3",
                  type: "text",
                  key: "Basis of calculating benefit share",
                  valKey: "value",
                },
              ],
            ],
          },
          {
            label:
              "Details of corrective actions taken or underway, based on any adverse order in intellectual property related disputes wherein usage of traditional knowledge is involved",
            th: ["Sr", "Title", "Value"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "9-0-3-0",
                },
                {
                  type: "static",
                  value: "Name of authority",
                  id: "9-0-3-0",
                },
                {
                  type: "text",
                  id: "9-0-3-0",
                  key: "Name of authority",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "9-0-3-1",
                },
                {
                  type: "static",
                  value: "Brief of the Case",
                  id: "9-0-3-1",
                },
                {
                  type: "text",
                  id: "9-0-3-1",
                  key: "Brief of the Case",
                  valKey: "value",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "9-0-3-2",
                },
                {
                  type: "static",
                  value: "Corrective action taken",
                  id: "9-0-3-2",
                },
                {
                  type: "text",
                  id: "9-0-3-2",
                  key: "Corrective action taken",
                  valKey: "value",
                },
              ],
            ],
          },
        ],
      },
    ],
    Principle9: [
      {
        type: "accordion",
        label:
          "Businesses should engage with and provide value to their consumers in a responsible manner",
        tables: [
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:
                    "Describe the mechanisms in place to receive and respond to consumer complaints and feedback.",
                  id: "10-0-0-0",
                },
                {
                  id: "10-0-0-0",
                  type: "text",
                  valKey: "value",
                  key: "Describe the mechanisms in place to receive and respond to consumer complaints and feedback.",
                },
              ],
            ],
          },
          {
            label:
              "Turnover of products and/ services as a percentage of turnover from all products/service that carry information about:",
            th: ["Sr", "Categories", "As a percentage to total turnover"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "10-0-1-0",
                },
                {
                  type: "static",
                  value:
                    "Environmental and social parameters relevant to the product",
                  id: "10-0-1-0",
                },
                {
                  id: "10-0-1-0",
                  type: "text",
                  key: "Environmental and social parameters relevant to the product",
                  valKey: "As a percentage to total turnover",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "10-0-1-1",
                },
                {
                  type: "static",
                  value: "Safe and responsible usage",
                  id: "10-0-1-1",
                },
                {
                  id: "10-0-1-1",
                  type: "text",
                  key: "Safe and responsible usage",
                  valKey: "As a percentage to total turnover",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "10-0-1-2",
                },
                {
                  type: "static",
                  value: "Recycling and/or safe disposal",
                  id: "10-0-1-2",
                },
                {
                  id: "10-0-1-2",
                  type: "text",
                  key: "Recycling and/or safe disposal",
                  valKey: "As a percentage to total turnover",
                },
              ],
            ],
          },
          {
            label: "Number of consumer complaints in respect of the following:",
            th: [
              "Sr",
              "Categories",
              "Received during the year",
              "Pending resolution at end of year",
              "Remarks",
            ],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "10-0-2-0",
                },
                {
                  type: "static",
                  value: "Data privacy",
                  id: "10-0-2-0",
                },
                {
                  id: "10-0-2-0",
                  type: "text",
                  key: "Data privacy",
                  valKey: "Received during the year",
                },
                {
                  id: "10-0-2-0",
                  type: "text",
                  key: "Data privacy",
                  valKey: "Pending resolution at end of year",
                },
                {
                  type: "text",
                  id: "10-0-2-0",
                  key: "Data privacy",
                  valKey: "Remarks",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "10-0-2-1",
                },
                {
                  type: "static",
                  value: "Advertising",
                  id: "10-0-2-1",
                },
                {
                  id: "10-0-2-1",
                  type: "text",
                  key: "Advertising",
                  valKey: "Received during the year",
                },
                {
                  id: "10-0-2-1",
                  type: "text",
                  key: "Advertising",
                  valKey: "Pending resolution at end of year",
                },
                {
                  type: "text",
                  id: "10-0-2-1",
                  key: "Advertising",
                  valKey: "Remarks",
                },
              ],
              [
                {
                  type: "static",
                  value: "c",
                  id: "10-0-2-2",
                },
                {
                  type: "static",
                  value: "Cyber-security",
                  id: "10-0-2-2",
                },
                {
                  type: "text",
                  id: "10-0-2-2",
                  key: "Cyber-security",
                  valKey: "Received during the year",
                },
                {
                  id: "10-0-2-2",
                  type: "text",
                  key: "Cyber-security",
                  valKey: "Pending resolution at end of year",
                },
                {
                  type: "text",
                  id: "10-0-2-2",
                  key: "Cyber-security",
                  valKey: "Remarks",
                },
              ],
              [
                {
                  type: "static",
                  value: "d",
                  id: "10-0-2-3",
                },
                {
                  type: "static",
                  value: "Delivery of essential",
                  id: "10-0-2-3",
                },
                {
                  id: "10-0-2-3",
                  type: "text",
                  key: "Delivery of essential",
                  valKey: "Received during the year",
                },
                {
                  id: "10-0-2-3",
                  type: "text",
                  key: "Delivery of essential",
                  valKey: "Pending resolution at end of year",
                },
                {
                  type: "text",
                  id: "10-0-2-3",
                  key: "Delivery of essential",
                  valKey: "Remarks",
                },
              ],
              [
                {
                  type: "static",
                  value: "e",
                  id: "10-0-2-4",
                },
                {
                  type: "static",
                  value: "services",
                  id: "10-0-2-4",
                },
                {
                  id: "10-0-2-4",
                  type: "text",
                  key: "services",
                  valKey: "Received during the year",
                },
                {
                  id: "10-0-2-4",
                  type: "text",
                  key: "services",
                  valKey: "Pending resolution at end of year",
                },
                {
                  type: "text",
                  id: "10-0-2-4",
                  key: "services",
                  valKey: "Remarks",
                },
              ],
              [
                {
                  type: "static",
                  value: "f",
                  id: "10-0-2-5",
                },
                {
                  type: "static",
                  value: "Restrictive Trade Practices",
                  id: "10-0-2-5",
                },
                {
                  type: "text",
                  id: "10-0-2-5",
                  key: "Restrictive Trade Practices",
                  valKey: "Received during the year",
                },
                {
                  id: "10-0-2-5",
                  type: "text",
                  key: "Restrictive Trade Practices",
                  valKey: "Pending resolution at end of year",
                },
                {
                  id: "10-0-2-5",
                  type: "text",
                  key: "Restrictive Trade Practices",
                  valKey: "Remarks",
                },
              ],
              [
                {
                  type: "static",
                  value: "g",
                  id: "10-0-2-6",
                },
                {
                  type: "static",
                  value: "Unfair Trade Practices",
                  id: "10-0-2-6",
                },
                {
                  id: "10-0-2-6",
                  type: "text",
                  key: "Unfair Trade Practices",
                  valKey: "Received during the year",
                },
                {
                  id: "10-0-2-6",
                  type: "text",
                  key: "Unfair Trade Practices",
                  valKey: "Pending resolution at end of year",
                },
                {
                  id: "10-0-2-6",
                  type: "text",
                  key: "Unfair Trade Practices",
                  valKey: "Remarks",
                },
              ],
              [
                {
                  type: "static",
                  value: "h",
                  id: "10-0-2-7",
                },
                {
                  type: "static",
                  value: "Other",
                  id: "10-0-2-7",
                },
                {
                  id: "10-0-2-7",
                  type: "text",
                  key: "Other",
                  valKey: "Received during the year",
                },
                {
                  id: "10-0-2-7",
                  type: "text",
                  key: "Other",
                  valKey: "Pending resolution at end of year",
                },
                {
                  type: "text",
                  id: "10-0-2-7",
                  key: "Other",
                  valKey: "Remarks",
                },
              ],
            ],
          },
          {
            label:
              "Details of instances of product recalls on account of safety issues:",
            th: ["Sr", "Categories", "Number", "Reason for recall"],
            tr: [
              [
                {
                  type: "static",
                  value: "a",
                  id: "10-0-3-0",
                },
                {
                  type: "static",
                  value: "Voluntary Recalls",
                  id: "10-0-3-0",
                },
                {
                  type: "text",
                  id: "10-0-3-0",
                  key: "Voluntary Recalls",
                  valKey: "Number",
                },
                {
                  id: "10-0-3-0",
                  type: "text",
                  key: "Voluntary Recalls",
                  valKey: "Reason for recall",
                },
              ],
              [
                {
                  type: "static",
                  value: "b",
                  id: "10-0-3-1",
                },
                {
                  type: "static",
                  value: "Forced Recalls",
                  id: "10-0-3-1",
                },
                {
                  type: "text",
                  id: "10-0-3-1",
                  key: "Forced Recalls",
                  valKey: "Number",
                },
                {
                  id: "10-0-3-1",
                  type: "text",
                  key: "Forced Recalls",
                  valKey: "Reason for recall",
                },
              ],
            ],
          },
          {
            label: "",
            th: [],
            tr: [
              [
                {
                  type: "static",
                  value:
                    "Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy",
                  id: "10-0-4-1",
                },
                {
                  id: "10-0-4-0",
                  type: "text",
                  valKey: "value",
                  key: "Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy",
                },
              ],
              [
                {
                  type: "static",
                  value:
                    "Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) If yes, provide details in brief. Did your entity carry out any survey with regard to consumer satisfaction relating to the major products / services of the entity, significant locations of operation of the entity or the entity as a whole? (Yes/No)",
                  id: "10-0-4-1",
                },
                {
                  id: "10-0-4-1",
                  type: "text",
                  valKey: "value",
                  key: "Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) If yes, provide details in brief. Did your entity carry out any survey with regard to consumer satisfaction relating to the major products / services of the entity, significant locations of operation of the entity or the entity as a whole? (Yes/No)",
                },
              ],
            ],
          },
        ],
      },
    ],
  };
  
  const WITH_ENVIORNMENT_BRSR_SECTION_TABS = [
    "SectionA",
    "SectionB",
    "Principle1",
    "Principle2",
    "Principle3",
    "Principle4",
    "Principle5",
    "Principle7",
    "Principle8",
    "Principle9",
  ];
  
  export { WITH_ENVIORNMENT_BRSR_FORM_FIELDS, WITH_ENVIORNMENT_BRSR_SECTION_TABS };
  
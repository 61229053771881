import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import DashboardWater from "../../components/dashboard/dashboardWater";
import DashboardWaste from "../../components/dashboard/dashboardWaste";
import DashboardEnergy from "../../components/dashboard/dashboardEnergy";
import DashboardAqi from "../../components/dashboard/dashboardAqi";
import DashboardBiodiversity from "../../components/dashboard/dashboardBiodiversity";
import { generateYearArray } from "../../utils/helpers";
import {
  GOVERNANCE_STATIC_GROUPS,
  GOVERNANCE_KPI_DASHBOARD,
  REVENUE_FIELDS,
} from "../../constants/constants";
import ColumnChart from "../../components/charts/columnChart";
import DonutChart from "../../components/charts/donutChart";
import PieChart from "../../components/charts/pieChart";
import { useMemo } from "react";
import { toJS } from "mobx";

import { filterData } from "../../helpers/groupDataCalculations";
import {
  CustomerAndPrivacyChart,
  CustomerHealthAndSafetyChart,
  DiversityAndEqualOpportunityChart,
  EmployeeHireGraph,
  MarketAndLabellingChart,
  NonDiscriminationChart,
  OhcChart,
  OhcChartInDashboard,
  SupplierSocialAssesmentChart,
  TrainingAndEducationGraph,
} from "./socialCharts";

const EnvironmentDashboardTab = observer(() => {
  const {
    dashboardStore: { fetchDashboardDetails, showAll },
    companyStore: {
      fetchFinancialData,
      getPerformance,
      fetchRevenueData,
      revenuePageData,
      companyData,
      companyUnit,
    },
  } = useStore();
  const company = toJS(companyData);
  const assignCategory =
    company?.assignCategory.map(({ catId }) => catId) || [];
  const category =
    company?.assignCategory.map(({ catIdAssignCategory: { name } }) => name) ||
    [];

  const assignSubCategory =
    company?.assignSubCategory
      .filter(({ catId }) => assignCategory.includes(catId))
      .map(({ subCatId }) => subCatId) || [];

  const assignKpi =
    company?.assignKpi
      .filter(({ catId }) => assignCategory.includes(catId))
      .filter(({ name }) => category.includes("Environment"))
      .filter(({ subCatId }) => assignSubCategory.includes(subCatId))
      .map(({ kpiIdAssignKpi: { name } }) => name) || [];

  const [year, setYear] = useState(new Date().getFullYear());
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchFinancialData({ year });
    getPerformance({ year });
    fetchDashboardDetails({ signal, year, companyUnit });
    fetchRevenueData({year});
    return () => {
      abortController.abort();
    };
  }, [
    fetchDashboardDetails,
    fetchFinancialData,
    fetchRevenueData,
    getPerformance,
    year,
    companyUnit,
    showAll,
  ]);

  const chartData = useMemo(() => {
    return ["waste", "all"].map((ele) => {
      const filteredData = revenuePageData?.revenue?.filter(
        (e) => e?.type === ele
      );
      const unit = filteredData?.[0]?.unit?.shortName || ""; // Assuming the unit is consistent within each type
      return {
        name: ele === "waste" ? "Revenue due to Waste" : "Total Revenue",
        unit,
        data: [year - 1, year].map((f) => {
          return (
            filteredData?.find((e) => f.toString() === e?.year?.toString())
              ?.value || 0
          );
        }),
      };
    });
  }, [revenuePageData, year]);

  // console.log("==========>",company.companiesData[0]?.role);
  return (
    <>
      <div className="d-flex justify-content-end ">
        <div className="w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center ">
          <label className="form-label fs-6 ">Year</label>

          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white "
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      {category.includes("Environment") && (
        <>
          {assignKpi.includes("Water") && (
            <div>
              <DashboardWater key="water-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Waste") && (
            <div>
              {/* <h3>Waste</h3> */}
              <DashboardWaste key="waste-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Energy") && (
            <div>
              {/* <h3>Energy</h3> */}
              <DashboardEnergy key="energy-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Emission") && (
            <div>
              {/* <h3>Emission</h3> */}
              <DashboardAqi key="aqi-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("BIODIVERSITY") && (
            <div>
              {/* <h3>Biodiversity</h3> */}
              <DashboardBiodiversity key="biodiversity-dashboard" year={year} />
            </div>
          )}
          {company.companiesData[0]?.role !== "employee" && (
            <div className="row h-100">
              <div className="col-xl-12">
                <div className="card h-100 dashboard-card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap align-items-start">
                      <h5 className="mb-3 text-muted"> Revenue Report</h5>
                      <div className="ms-auto">
                        <div className="toolbar d-flex flex-wrap gap-2 ">
                          <div className="">
                            <p className="text-muted mb-1">Unit</p>
                            <h5 className="unit-value">
                              {chartData.length > 0 ? chartData[0].unit : " "}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ColumnChart
                      data={chartData}
                      categories={[
                        `FY${(year - 1).toString().slice(2)}`,
                        `FY${year.toString().slice(2)}`,
                      ]}
                      yaxis={[]}
                      unit={chartData.length > 0 ? chartData[0].unit : " "}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
});

const GovernanceDashboardTab = observer(() => {
  const {
    governance: { fetchDashboard, dashboard },
    companyStore: { companyUnit },
    dashboardStore: { showAll },
  } = useStore();
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchDashboard({ year, companyUnit });
  }, [fetchDashboard, year, companyUnit, showAll]);

  const specificFieldsForPieChart = useMemo(
    () => [
      "Existence and effectiveness of a risk management framework.",
      "Existence of a whistleblower protection policy.",
      "Voting results of key shareholder resolutions.",
      "Timeliness and accuracy of financial and non-financial reporting.",
      "Compliance with regulatory reporting requirements.",
      "Frequency of ESG-related disclosures and updates.",
      "Frequency of shareholder engagement meetings.",
      "Existence of a documented corporate governance framework.",
      "Compliance with international governance standards and best practices.",
      "Existence of data privacy and cybersecurity policies.",
    ],
    []
  );

  const getColumnChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const previous =
        dashboard?.[(Number(year) - 1).toString()]?.[group] || [];
      const toBeUsed = current?.length <= previous?.length ? previous : current;
      const result = toBeUsed
        ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
        ?.map((ele) => {
          return {
            name: ele.fieldName,
            data: [
              Number(previous.find((e) => e.fieldId === ele.fieldId)?.value) ||
              0,
              Number(current.find((e) => e.fieldId === ele.fieldId)?.value) ||
              0,
            ],
          };
        });
      return result || [];
    },
    [dashboard, specificFieldsForPieChart]
  );

  const getDonutChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const data =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
          ?.map((ele) => Number(ele.value) || 0) || [];
      const labels =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
          ?.map((ele) => ele.fieldName) || [];
      return { data, labels };
    },
    [dashboard, specificFieldsForPieChart]
  );
const getPieChartData = useCallback(
  ({ group, year }) => {
    const current = dashboard?.[Number(year).toString()]?.[group] || [];

    const filteredData =
      current
        ?.filter((ele) => specificFieldsForPieChart.includes(ele.fieldName))
        ?.flatMap((ele) => {
          // Split the value if it's comma-separated (e.g., "Yes, No, No")
          const values = ele.value.split(",").map((val) => val.trim());
          // Map the split values to their corresponding numerical representation
          return values.map((val) => ({
            label: ele.fieldName,
            value:
              val === "Yes"
                ? 100
                : val === "No"
                  ? 90
                  : Number(val), // Handle numeric values directly
          }));
        }) || [];

    const groupedData = filteredData.reduce((acc, { label, value }) => {
      if (!acc[label]) {
        acc[label] = [];
      }
      acc[label].push(value);
      return acc;
    }, {});

    return groupedData;
  },
  [dashboard, specificFieldsForPieChart]
);


  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className=" w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center">
          <label className="form-label fs-6 ">Year</label>
          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white"
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {GOVERNANCE_KPI_DASHBOARD.map((group) => (
          <div className="row h-100 align-items-stretch" key={group}>
            {/* Render ColumnChart and DonutChart for groups other than "F" */}
            {group !== "F" && (
              <>
                <div className="col-xl-8">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-start">
                        <h5 className="mb-3 text-muted">
                          {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                        </h5>
                      </div>
                      <div>
                        <ColumnChart
                          data={getColumnChartData({ group, year })}
                          categories={[
                            `FY${(year - 1).toString().slice(2)}`,
                            `FY${year.toString().slice(2)}`,
                          ]}
                          max={null}
                          yaxis={[]}
                          unit={" "}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-start">
                        <h5 className="mb-3 text-muted">
                          {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                        </h5>
                      </div>
                      <div className="donut">
                        <DonutChart
                          height={512}
                          data={getDonutChartData({ group, year }).data}
                          labels={getDonutChartData({ group, year }).labels}
                          unit={" "}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {Object.entries(getPieChartData({ group, year })).length > 0 && (
              <div className="w-100">
                <div className="mb-0 h-100 col-xl-12">
                  <div className="d-flex flex-wrap align-items-start">
                    {group === "F" && (
                      <h5 className="mb-3 text-muted">
                        {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                      </h5>
                    )}
                  </div>
                  <div className="mb-0 h-100 col-xl-12">
                    <div className="pie row">
                      {Object.entries(getPieChartData({ group, year })).map(
                        ([label, data], index) => {
                          // Map series values to corresponding labels
                          const labels = data.map((value) =>
                            value === 100 ? "Yes" : value === 90 ? "No" : ""
                          );

                          return (
                            <div className="col-xl-4 mt-3" key={index}>
                              <div className="card h-100">
                                <div className="card-body">
                                  <h6>{label}</h6>
                                  <PieChart
                                    height={380}
                                    series={data}
                                    labels={labels}
                                    unit={" "}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

const SocialDashboardTab = observer(() => {
  const {
    social: { fetchChartData, chartData },
    companyStore: { companyData, companyUnit },
    dashboardStore: { showAll },
  } = useStore();

  const company = toJS(companyData);
  const assignCategory =
    company?.assignCategory.map(({ catId }) => catId) || [];
  const category =
    company?.assignCategory.map(({ catIdAssignCategory: { name } }) => name) ||
    [];

  const assignSubCategory =
    company?.assignSubCategory
      .filter(({ catId }) => assignCategory.includes(catId))
      .map(({ subCatId }) => subCatId) || [];

  const assignKpi =
    company?.assignKpi
      .filter(({ catId }) => assignCategory.includes(catId))
      .filter(({ subCatId }) => assignSubCategory.includes(subCatId))
      .map(({ kpiIdAssignKpi: { name } }) => name) || [];
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchChartData({ year, companyUnit });
  }, [fetchChartData, year, companyUnit, showAll]);
  const categories = [
    `FY${(year - 1).toString().slice(2)}`,
    `FY${year.toString().slice(2)}`,
  ];

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className=" w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center">
          <label className="form-label fs-6 ">Year</label>
          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white"
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      {category.includes("Social") && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {assignKpi.includes("Employment") && (
              <EmployeeHireGraph
                categories={categories}
                data={chartData?.filter((e) => e.kpiName === "Employment")}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("OH&C") && (
              <OhcChart
                categories={categories}
                data={filterData(chartData, "kpiName", "OH&C")}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Training and Education") && (
              <TrainingAndEducationGraph
                data={filterData(
                  chartData,
                  "kpiName",
                  "Training and Education"
                )}
                year={Number(year)}
                categories={categories}
              />
            )}
            {assignKpi.includes("Diversity And Equal Opportunity") && (
              <DiversityAndEqualOpportunityChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Diversity And Equal Opportunity"
                )}
                year={Number(year)}
                categories={categories}
              />
            )}
            {assignKpi.includes("Non-Discrimination") && (
              <NonDiscriminationChart
                data={filterData(chartData, "kpiName", "Non-Discrimination")}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Supplier Social Assessment") && (
              <SupplierSocialAssesmentChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Supplier Social Assessment"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Customer Health and Safety") && (
              <CustomerHealthAndSafetyChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Customer Health and Safety"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Marketing and Labeling") && (
              <MarketAndLabellingChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Marketing and Labeling"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Customer Privacy") && (
              <CustomerAndPrivacyChart
                data={filterData(chartData, "kpiName", "Customer Privacy")}
                categories={categories}
                year={Number(year)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

const CommonDashboardTab = observer(() => {
  const {
    governance: { fetchDashboard, dashboard },
    social: { fetchChartData, chartData },
    companyStore: { companyUnit, fetchRevenueData, revenuePageData },
    dashboardStore: { showAll },
  } = useStore();
  const specificFieldsForPieChart = useMemo(
    () => [
      "Existence and effectiveness of a risk management framework.",
    ],
    []
  );
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchDashboard({ year, companyUnit });
    fetchChartData({ year, companyUnit });
    fetchRevenueData({ year, companyUnit });
  }, [fetchDashboard, fetchChartData, year, companyUnit, showAll, fetchRevenueData]);

  const categories = [
    `FY${(year - 1).toString().slice(2)}`,
    `FY${year.toString().slice(2)}`,
  ];
  //Data extraction for Water and Energy Intensity charts
  const getIntensityChartData = useCallback((type, year) => {
    const fieldData = revenuePageData?.fieldData || [];
    const currentYear = Number(year).toString();
    const previousYear = (Number(year) - 1).toString();
    const currentYearData = fieldData.filter(data => data.type === type && data.year === currentYear);
    const previousYearData = fieldData.filter(data => data.type === type && data.year === previousYear);
    // Create maps with the title as keys to match the field.valKey (if needed)
    const currentYearMap = currentYearData.reduce((acc, data) => {
      acc[data.title] = data;
      return acc;
    }, {});
    const previousYearMap = previousYearData.reduce((acc, data) => {
      acc[data.title] = data;
      return acc;
    }, {});
    // Map REVENUE_FIELDS to the chart format
    return REVENUE_FIELDS.filter(field => field.type === type).map(field => {
      const dataValues = [
        Number(previousYearMap[field.label]?.value.toFixed(0)) || 0,
        Number(currentYearMap[field.label]?.value.toFixed(0)) || 0
      ];
      return {
        name: field.legend,
        data: dataValues
      };

    });
  }, [revenuePageData?.fieldData]);
// Extract revenue unit based on year
const getRevenueUnit = (year) => {
  const revenueData = revenuePageData?.revenue?.find((e) => e.type === "all" && e.year === year) || {};
  return revenueData?.unit?.shortName || '(in ₹ Mn)'; // Get the short name of the unit for the selected year
};
  // Function to get data for Governance "E" group charts
  const getGovernanceDonutChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const data =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName))
          ?.map((ele) => Number(ele.value) || 0) || [];
      const labels =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName))
          ?.map((ele) => ele.fieldName) || [];
      return { data, labels };
    },
    [dashboard, specificFieldsForPieChart]
  );
  const getGovernancePieChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const filteredData =
        current
          ?.filter((ele) => specificFieldsForPieChart.includes(ele.fieldName))
            ?.flatMap((ele) => {
              // Split the value if it's comma-separated (e.g., "Yes, No, No")
              const values = ele.value.split(",").map((val) => val.trim());
              // Map the split values to their corresponding numerical representation
              return values.map((val) => ({
                label: ele.fieldName,
                value:
                  val === "Yes"
                    ? 100
                    : val === "No"
                      ? 90
                      : Number(val), // Handle numeric values directly
              }));
            }) || [];

      const groupedData = filteredData.reduce((acc, { label, value }) => {
        if (!acc[label]) {
          acc[label] = [];
        }
        acc[label].push(value);
        return acc;
      }, {});

      return groupedData;
    },
    [dashboard, specificFieldsForPieChart]
  );
  const getColumnChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const previous =
        dashboard?.[(Number(year) - 1).toString()]?.[group] || [];
      const toBeUsed = current?.length <= previous?.length ? previous : current;
      const result = toBeUsed
        ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
        ?.map((ele) => {
          return {
            name: ele.fieldName,
            data: [
              Number(previous.find((e) => e.fieldId === ele.fieldId)?.value) ||
              0,
              Number(current.find((e) => e.fieldId === ele.fieldId)?.value) ||
              0,
            ],
          };
        });
      return result || [];
    },
    [dashboard, specificFieldsForPieChart]
  );

  return (
    <>
      <div className="d-flex justify-content-end">
        <div className=" w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center">
          <label className="form-label fs-6 ">Year</label>
          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white"
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Enviornment Charts */}
        <div className="card p-4">
          <div className="row h-100">
            <div className="d-flex flex-wrap align-items-start">
              <h2 className="mb-3 text-muted">Enviornment</h2>
            </div>
            <div className="col-xl-6">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="mb-3 text-muted">Energy Intensity</h5>
                  <ColumnChart
                    data={getIntensityChartData("energy", year)}
                    categories={categories}
                    yaxis={[]}
                    unit={getRevenueUnit(year)}
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="mb-3 text-muted">Water Intensity</h5>
                  {console.log(categories,'dshgdhsgfyh')}
                  
                  <ColumnChart
                    data={getIntensityChartData("water", year)}
                    categories={categories}
                    yaxis={[]}
                    unit={getRevenueUnit(year)}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* Social Charts */}
        <div className="card p-4">
          <div className="row h-100">
            <div className="d-flex flex-wrap align-items-start">
              <h2 className="mb-3 text-muted">Social</h2>
            </div>
            {/* Social Charts */}
            <EmployeeHireGraph
              categories={categories}
              data={filterData(chartData, "kpiName", "Employment")}
              year={Number(year)}
            />
            <OhcChartInDashboard
              categories={categories}
              data={filterData(chartData, "kpiName", "OH&C")}
              year={Number(year)}
              dashboard={true}

            />

            <TrainingAndEducationGraph
              categories={categories}
              data={filterData(chartData, "kpiName", "Training and Education")}
              year={Number(year)}
            />
          </div>
        </div>

        {/* Governance Donut and Pie Charts for "B" Group */}
        <div className="card p-4">
          <div className="row h-100">
            <div className="d-flex flex-wrap align-items-start">
              <h2 className="mb-3 text-muted">Governance</h2>
            </div>
            <div className="col-xl-6">
              <div className="card h-100">
                <div className="card-body">
                  <div className="d-flex flex-wrap align-items-start">
                    <h5 className="mb-3 text-muted">
                      {GOVERNANCE_STATIC_GROUPS.GovernanceKpis["B"]}
                    </h5>
                  </div>
                  <div>
                    <ColumnChart
                      data={getColumnChartData({ group: "B", year })}
                      categories={[
                        `FY${(year - 1).toString().slice(2)}`,
                        `FY${year.toString().slice(2)}`,
                      ]}
                      max={null}
                      yaxis={[]}
                      unit={" "}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="mb-3 text-muted"> {GOVERNANCE_STATIC_GROUPS.GovernanceKpis["B"]}</h5>
                  <DonutChart
                    height={512}
                    data={getGovernanceDonutChartData({ group: "B", year }).data}
                    labels={getGovernanceDonutChartData({ group: "B", year }).labels}
                    unit={" "}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-xl-4">
              <div className="card h-100">
                <div className="card-body">
                  <div className="row">
                    {Object.entries(getGovernancePieChartData({ group: "B", year })).map(
                      ([label, data], index) => (
                        <><h6>{label}</h6><div className="col-xl-12 mt-3" key={index}>
                          <PieChart
                            height={380}
                            series={data}
                            labels={data.map((value) => (value === 100 ? "Yes" : "No"))}
                            unit={" "} />
                        </div></>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>

  );
});


export { EnvironmentDashboardTab, GovernanceDashboardTab, SocialDashboardTab, CommonDashboardTab };

import { WITH_ENVIORNMENT_BRSR_FORM_FIELDS } from "../constants/withEnviornmentBrsrConstant";
import { WITHOUT_ENVIORNMENT_BRSR_FORM_FIELDS } from "../constants/withoutEnviornmentBrsrConstant";
import {
  ENERGY_UNITS_1,
  ENERGY_UNITS_3,
  ENERGY_UNITS_4,
  WASTE_UNITS_1,
  WATER_UNITS_1,
} from "../constants/constants";
import * as htmlToImage from "html-to-image";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const formatDateForTable = (inputDate) => {
  const date = new Date(inputDate);
  return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
};

const addGroupHeadingData = (data) =>
  data?.reduce(
    ({ key, values }, acc, index) => {
      if (key === null) {
        return {
          key: acc.group,
          values: [acc],
        };
      }
      if (index === data.length - 1 && key !== acc.group) {
        return {
          key: acc.group,
          values: [
            ...values,
            {
              type: "groupBody",
              group: key,
            },
            acc,
            {
              type: "groupBody",
              group: acc.group,
            },
          ],
        };
      }
      if (key === acc.group) {
        if (index === data.length - 1) {
          return {
            key: acc.group,
            values: [
              ...values,
              acc,
              {
                type: "groupBody",
                group: key,
              },
            ],
          };
        }
        return {
          key,
          values: [...values, acc],
        };
      } else {
        return {
          key: acc.group,
          values: [
            ...values,
            {
              type: "groupBody",
              group: key,
            },
            acc,
          ],
        };
      }
    },
    {
      key: null,
    }
  ).values;

function groupByConsecutiveGroups(data) {
  const result = [];
  let currentGroup = [];

  data.forEach((item, index) => {
    if (index === 0 || item.group === data[index - 1].group) {
      currentGroup.push(item);
    } else {
      result.push(currentGroup);
      currentGroup = [item];
    }
  });

  if (currentGroup.length) {
    result.push(currentGroup);
  }

  return result;
}

const generateAqiPayload = ({
  data,
  companyUnit,
  kpiId,
  subCatId,
  catId,
  companyId,
}) =>
  data.map((ele) => ({
    ...ele,
    companyUnit,
    kpiId,
    subCatId,
    catId,
    companyId,
    fieldGroup: ele.group,
    groupName: ele.group,
  }));

const filterArray = (data, arr) => data.filter((e) => arr.includes(e.name));

const getUnits = ({ units, data, type }) => {
  switch (type) {
    case "energy":
      if (
        [
          "Intensity of Energy Consumption Within the Organisation/Revenue",
          "Intensity of Energy Consumption outside of the organisation/Revenue",
          "Intensity of Total Energy Consumption/Revenue",
        ].includes(data.fieldName)
      ) {
        return filterArray(units, [
          "Unit Of energy KWh Intensity in rupees",
          "Unit Of energy KWh Intensity in dollar",
          "Unit Of energy KWh Intensity in euro",
          "Unit Of energy J Intensity in rupees",
          "Unit Of energy J Intensity in dollar",
          "Unit Of energy J Intensity in euro",
          "Unit Of energy Gj Intensity in dollar",
          "Unit Of energy Gj Intensity in rupees",
          "Unit Of energy Gj Intensity in euro",
          "Unit Of energy Mj Intensity in rupees",
          "Unit Of energy Mj Intensity in dollar",
          "Unit Of energy Mj Intensity in euro",
        ]);
      }
      if (
        ["Describe the Estimate Energy Reduction **"].includes(data.fieldName)
      ) {
        return filterArray(units, ENERGY_UNITS_3);
      }

      if (data.group === "D1") {
        return filterArray(units, ENERGY_UNITS_4);
      }
      if (data.fieldName === "Revenue") {
        return filterArray(units, [
          "Revenue",
          "Revenue in dollar",
          "Revenue in euro",
        ]);
      }
      if (data.fieldName === "Finished goods quantity produced") {
        return filterArray(units, ["Metric tonn", "Kilo Gram"]);
      }
      if (
        [
          "Intensity of Energy Consumption Within the Organisation/Finished goods quantity produced",
          "Intensity of Energy Consumption outside of the organisation/Finished goods quantity produced",
          "Intensity of Total Energy Consumption/Finished goods quantity produced",
        ].includes(data.fieldName)
      ) {
        return filterArray(units, [
          "Unit Of goods KWh/Kg Intensity Gram",
          "Unit Of goods Mj/Kg Intensity Gram",
          "Unit Of goods J/Kg Intensity Gram",
          "Unit Of goods Mj/Kg Intensity Gram",
          "Unit Of goods Gj/Kg Intensity Gram",
          "Unit Of goods KWh/MT Intensity tonn",
          "Unit Of goods Mj/MT Intensity tonn",
          "Unit Of goods J/MT Intensity tonn",
          "Unit Of goods Mj/MT Intensity tonn",
          "Unit Of goods Gj/MT Intensity tonn",
        ]);
      }

      return filterArray(units, ENERGY_UNITS_1);

    case "biodiversity":
      if (
        data.fieldName === "Size of water body affected and related habitat"
      ) {
        return filterArray(units, ["Kilo miter cube"]);
      }
      if (data.group === "C") {
        return filterArray(units, ["Acre"]);
      }
      return filterArray(units, ["Not otherwise specified"]);
    case "waste":
      if (data.fieldName === "Revenue") {
        return filterArray(units, [
          "Revenue",
          "Revenue in dollar",
          "Revenue in euro",
        ]);
      }
      if (data.fieldName === "Total Expenses") {
        return filterArray(units, [
          "Revenue",
          "Revenue in dollar",
          "Revenue in euro",
        ]);
      }
      return filterArray(units, WASTE_UNITS_1);
    case "water":
      if (data.fieldName === "Revenue/per year") {
        return filterArray(units, [
          "Revenue",
          "Revenue in dollar",
          "Revenue in euro",
        ]);
      }
      if (data.fieldName === "( Total Water consumption / Revenue )") {
        return filterArray(units, [
          "Unit Of Water M3 Intensity in rupees",
          "Unit Of Water KL Intensity in rupees",
          "Unit Of Water ML Intensity in rupees",
          "Unit Of Water M3 Intensity in dollar",
          "Unit Of Water KL Intensity in dollar",
          "Unit Of Water ML Intensity in dollar",
          "Unit Of Water M3 Intensity in euro",
          "Unit Of Water KL Intensity in euro",
          "Unit Of Water ML Intensity in euro",
        ]);
      }
      if (data.fieldName === "Finished Goods Quantity Produced") {
        return filterArray(units, ["Metric tonn", "Kilo Gram"]);
      }
      if (
        data.fieldName ===
        "( Total Water consumption / Finished Goods Quantity Produced )"
      ) {
        return filterArray(units, [
          "Unit Of goods KL/Kg Intensity Gram",
          "Unit Of goods ML/Kg Intensity Gram",
          "Unit Of goods M3/Kg Intensity Gram",
          "Unit Of goods KL/MT Intensity tonn",
          "Unit Of goods ML/MT Intensity tonn",
          "Unit Of goods M3/MT Intensity tonn",
        ]);
      }
      return filterArray(units, WATER_UNITS_1);

    case "emission":
    case "aqi":
      if (data.fieldName === "Finished Goods Quantity Produced") {
        return filterArray(units, ["Metric tonn", "Kilo Gram"]);
      }
      if (
        [
          "Scope-1 Emission Intensity/Finished goods quantity produced",
          "Scope-2 Emission Intensity/Finished goods quantity produced",
          "GHG Emission Intensity/Finished goods quantity produced",
          "Scope-3 Emission Intensity/Finished goods quantity produced",
        ].includes(data.fieldName)
      ) {
        return filterArray(units, [
          "Unit Of goods MT/Kg Intensity Gram",
          "Unit Of goods MT/MT Intensity tonn",
          "Unit Of goods t-CO2/Kg Intensity",
          "Unit Of goods t-CO2/MT Intensity",
        ]);
      }
      if (data.fieldName === "Revenue") {
        return filterArray(units, [
          "Revenue",
          "Revenue in dollar",
          "Revenue in euro",
        ]);
      }
      if (
        [
          "Scope-1 Emission Intensity/Revenue",
          "Scope-2 Emission Intensity/Revenue",
          "GHG Emission Intensity/Revenue",
          "Scope-3 Emission Intensity/Revenue",
        ].includes(data.fieldName)
      ) {
        return filterArray(units, [
          "Unit Of Emission MT Intensity in rupees",
          "Unit Of Emission MT Intensity in dollar",
          "Unit Of Emission MT Intensity in euro",
          "Unit Of Emission t-CO2 Intensity in rupees",
          "Unit Of Emission t-CO2 Intensity in dollar",
          "Unit Of Emission t-CO2 Intensity in euro",
        ]);
      }

      if (data.group === "B1") {
        return filterArray(units, [
          "Tonn Carbon di-oxide",
          "Metric tonn",
          "Kilo watt hours",
        ]);
      }
      if (data.fieldName === "Gases included in the calculation") {
        return filterArray(units, [
          "Carbon di-oxide",
          "Methane",
          "Nitrous Oxide",
          "HydroFluro Carbons",
          "Potent Greenhouse Gas",
          "Sulphur HexaFluoride (SHF)",
          "Nitrogen trifluride",
          "All Gases",
        ]);
      }

      if (data.group === "H") {
        return filterArray(units, [
          "Published emission factors",
          "Direct measurement of emissions ( Such As online Analizyers)",
          "Site-specific data",
        ]);
      }
      if (data.group === "G" || data.group === "I") {
        return filterArray(units, [
          "Tonn Carbon di-oxide",
          "Metric tonn",
          "Kilo watt hours",
          "Kilo Gram",
        ]);
      }
      if (data.fieldName === "Finished goods quantity produced") {
        return filterArray(units, ["Metric tonn", "Kilo Gram"]);
      }
      return filterArray(units, ["Tonn Carbon di-oxide", "Metric tonn"]);

    default:
      break;
  }
};
const generatePayload = ({
  data,
  companyUnit,
  kpiId,
  subCatId,
  catId,
  companyId,
  groupCDropdown,
}) =>
  data.map((ele) => ({
    ...ele,
    companyUnit,
    kpiId,
    subCatId,
    catId,
    companyId,
    fieldGroup: ele.group,
    groupName: ele.group,
    groupCDropdown,
  }));

const getFormDataUrl = function (type) {
  let result = "";
  switch (type) {
    case "water":
      result = "add-water/getFormData";
      break;
    case "waste":
      result = "waste-management/getFormData";
      break;
    case "energy":
      result = "energy/getFormData";
      break;
    case "aqi":
      result = "emission/getFormData";
      break;
    case "biodiversity":
      result = "biodiversity/getFormData";
      break;
    default:
      break;
  }
  return result;
};

const getCompartaorForSorting = (a, b, key, type = "ASC") => {
  if (type === "ASC") {
    return Number(a[key]) - Number(b[key]);
  } else {
    return Number(b[key]) - Number(a[key]);
  }
};

const calculateMaxWithIncrease = (value, key, type = "Inc") => {
  if (type === "Inc") {
    return Number(value[key]) * 1.1;
  } else {
    return Number(value[key]) * 0.9;
  }
};

const getRoundedValue = (value, places = 3) =>
  Number(Number(value).toFixed(places));

const generateYearArray = (length = 5) =>
  Array.from({ length }).map((_, index) => new Date().getFullYear() - index);

function convertToNestedFormat(data, label) {
  const nestedData = [];

  data?.sort((a, b) => parseInt(a.position) - parseInt(b.position));

  data?.forEach?.((item) => {
    const groupLevels = item.group.split("_");
    let currentLevel = nestedData;

    groupLevels.forEach((level, index) => {
      const groupName = groupLevels.slice(0, index + 1).join("_");
      let existingGroup = currentLevel.find(
        (group) => group.group === groupName
      );

      if (!existingGroup) {
        existingGroup = {
          group: groupName,
          value: [],
          label: label[groupName],
        };
        currentLevel.push(existingGroup);
        currentLevel.sort((a, b) => a.group.localeCompare(b.group)); // Ensure groups are sorted
      }

      currentLevel = existingGroup.value;
    });

    currentLevel.push({
      position: item.position,
      fieldId: item.fieldId,
      fieldName: item.fieldName,
      guidance: item.guidance,
      value: item.value,
      comment: item.comment,
      group: item.group,
      attachment: item.attachment,
    });

    currentLevel.sort((a, b) => a.position - b.position); // Ensure items are sorted by position
  });

  return nestedData;
}

const updateField = (data, fieldId, newValues) => {
  if (data?.length === 0) return [];
  return data.map((item) => {
    if (item.value && Array.isArray(item.value)) {
      return {
        ...item,
        value: updateField(item.value, fieldId, newValues),
      };
    }
    if (item.fieldId === fieldId) {
      return {
        ...item,
        ...newValues,
      };
    }
    return item;
  });
};

function getAllFieldValues(data) {
  let fields = [];

  function recurse(node) {
    if (Array.isArray(node.value)) {
      node.value.forEach((child) => recurse(child));
    } else if (node.fieldId !== undefined) {
      fields.push(node);
    }
  }

  recurse(data);
  return fields;
}

function convertToNestedFormatEnv({ data, label }) {
  const nestedData = [];

  data?.sort((a, b) => parseInt(a.position) - parseInt(b.position));

  data?.forEach?.((item) => {
    const groupLevels = item.group.split("");
    let currentLevel = nestedData;

    groupLevels.forEach((level, index) => {
      const groupName = groupLevels.slice(0, index + 1).join("");
      let existingGroup = currentLevel.find(
        (group) => group.group === groupName
      );

      if (!existingGroup) {
        existingGroup = {
          group: groupName,
          value: [],
          label: label[groupName],
        };
        currentLevel.push(existingGroup);
        currentLevel.sort((a, b) => a.group.localeCompare(b.group)); // Ensure groups are sorted
      }

      currentLevel = existingGroup.value;
    });

    currentLevel.push(item);

    currentLevel.sort((a, b) => Number(a.position) - Number(b.position)); // Ensure items are sorted by position
  });
  return nestedData;
}

const getApprovalUnits = ({ data, units, type, totalData }) => {
  switch (type) {
    case "water":
      return (() => {
        const revenue =
          units
            ?.find(
              (ele) =>
                ele.id ===
                totalData?.find((e) => e.fieldName === "Revenue/per year")?.unit
            )
            ?.name?.split(" ") || [];
        const revenueUnit =
          revenue?.length === 1 ? "rupees" : revenue[revenue.length - 1];
        const finishedGoods = units?.find(
          (ele) =>
            ele.id ===
            totalData?.find(
              (e) => e.fieldName === "Finished goods quantity produced"
            )?.unit
        )?.shortName;

        const unitField = units.find(
          (ele) => ele.id === totalData?.find((e) => e.group === "A")?.unit
        )?.shortName;

        const dataUnits =
          data.fieldName === "( Total Water consumption / Revenue )"
            ? getUnits({ units, data, type: "water" }).filter(
                (ele) =>
                  ele.name.includes(
                    ["dollar", "rupees", "euro"].includes(revenueUnit)
                      ? revenueUnit
                      : ""
                  ) && ele.name.includes(unitField || "")
              )
            : data.fieldName ===
              "( Total Water consumption / Finished Goods Quantity Produced )"
            ? getUnits({ units, data, type: "water" }).filter(
                (ele) =>
                  ele.shortName.includes(
                    ["MT", "Kg"].includes(finishedGoods) ? finishedGoods : ""
                  ) && ele.shortName.includes(unitField || "")
              )
            : getUnits({ units, data, type: "water" });
        return dataUnits;
      })();

    case "energy":
      return (() => {
        const revenue =
          units
            ?.find(
              (ele) =>
                ele.id ===
                totalData?.find((e) => e.fieldName === "Revenue")?.unit
            )
            ?.name?.split(" ") || [];

        const revenueUnit =
          revenue?.length === 1 ? "rupees" : revenue[revenue.length - 1];
        const unitField = units.find(
          (ele) => ele.id === totalData?.find((ele) => ele.group === "A")?.unit
        )?.shortName;

        const finishedGoods = units?.find(
          (ele) =>
            ele.id ===
            totalData?.find(
              (e) => e.fieldName === "Finished goods quantity produced"
            )
        )?.shortName;

        const dataUnits =
          data.fieldName === "Intensity of Total Energy Consumption/Revenue" ||
          data.fieldName ===
            "Intensity of Energy Consumption outside of the organisation/Revenue" ||
          data.fieldName ===
            "Intensity of Energy Consumption Within the Organisation/Revenue"
            ? getUnits({ units, data, type: "energy" }).filter(
                (ele) =>
                  ele.name.includes(
                    ["dollar", "rupees", "euro"].includes(revenueUnit)
                      ? revenueUnit
                      : ""
                  ) && ele.name.includes(unitField || "")
              )
            : data.fieldName ===
                "Intensity of Energy Consumption Within the Organisation/Finished goods quantity produced" ||
              data.fieldName ===
                "Intensity of Energy Consumption outside of the organisation/Finished goods quantity produced" ||
              data.fieldName ===
                "Intensity of Total Energy Consumption/Finished goods quantity produced"
            ? getUnits({ units, data, type: "energy" }).filter(
                (ele) =>
                  ele.shortName.includes(
                    ["MT", "Kg"].includes(finishedGoods) ? finishedGoods : ""
                  ) && ele.shortName.includes(unitField || "")
              )
            : getUnits({ units, data, type: "energy" });
        return dataUnits;
      })();
    case "waste":
      return getUnits({ units, data, type: "waste" });

    case "aqi":
    case "emission":
      return (() => {
        const revenue =
          units
            ?.find(
              (ele) =>
                ele.id ===
                totalData?.find((e) => e.fieldName === "Revenue")?.unit
            )
            ?.name?.split(" ") || [];

        const revenueUnit =
          revenue?.length === 1 ? "rupees" : revenue[revenue.length - 1];

        const unitField = units.find(
          (ele) =>
            ele.id ===
            totalData?.find((ele) => ele.group === "A")?.value?.[0]?.unit
        )?.shortName;

        const finishedGoods = units?.find(
          (ele) =>
            ele.id ===
            totalData?.find(
              (e) => e.fieldName === "Finished goods quantity produced"
            )?.unit
        )?.shortName;

        const dataUnits = [
          "Scope-1 Emission Intensity/Revenue",
          "Scope-2 Emission Intensity/Revenue",
          "Scope-3 Emission Intensity/Revenue",
          "GHG Emission Intensity/Revenue",
        ].includes(data.fieldName)
          ? getUnits({ units, data, type: "aqi" }).filter(
              (ele) =>
                ele.name.includes(
                  ["dollar", "rupees", "euro"].includes(revenueUnit)
                    ? revenueUnit
                    : ""
                ) && ele.name.includes(unitField || "")
            )
          : [
              "Scope-1 Emission Intensity/Finished goods quantity produced",
              "Scope-2 Emission Intensity/Finished goods quantity produced",
              "Scope-3 Emission Intensity/Finished goods quantity produced",
              "GHG Emission Intensity/Finished goods quantity produced",
            ].includes(data.fieldName)
          ? getUnits({ units, data, type: "aqi" }).filter(
              (ele) =>
                ele.shortName.includes(
                  ["MT", "Kg"].includes(finishedGoods)
                    ? `/${finishedGoods}`
                    : ""
                ) && ele.shortName.includes(unitField || "")
            )
          : getUnits({ units, data, type: "aqi" });
        return dataUnits;
      })();
    case "biodiversity":
      return getUnits({ data, units, type: "biodiversity" });
    default:
      return [];
  }
};

const socialNestedData = (data, label) => {
  const nestedData = [];

  data?.sort((a, b) => parseInt(a.position) - parseInt(b.position));

  data?.forEach?.((item) => {
    const groupLevels = item.group.split("_");
    let currentLevel = nestedData;

    groupLevels.forEach((level, index) => {
      const groupName = groupLevels.slice(0, index + 1).join("_");
      let existingGroup = currentLevel.find(
        (group) => group.group === groupName
      );

      if (!existingGroup) {
        existingGroup = {
          group: groupName,
          value: [],
          label: label[groupName],
        };
        currentLevel.push(existingGroup);
        currentLevel.sort((a, b) => a.group.localeCompare(b.group)); // Ensure groups are sorted
      }

      currentLevel = existingGroup.value;
    });

    currentLevel.push({ ...item });

    currentLevel.sort((a, b) => Number(a.position) - Number(b.position)); // Ensure items are sorted by position
  });

  return nestedData;
};

const findKpi = ({ kpis, value, field }) =>
  kpis?.find((ele) => ele?.[field] === value) || {};

const addChartToEditor = async ({ editor, id, style }) => {
  const doc = document.getElementById(id);
  const chart = doc.querySelector("#chart");

  const svgUrl = await htmlToImage.toSvg(chart);
  let styleString;
  if (style) {
    styleString =
      Object.entries(style)
        ?.map(
          ([key, value]) =>
            `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value}`
        )
        .join("; ") || "";
  }
  const content = `<img style="${styleString}" src="${svgUrl}"/>`;
  editor?.editor?.focus?.();
  editor?.editor?.selection?.setContent?.(content);
};

const getBrsrInitialData = (isOnlyBrsr) => {
  // const { Principle6, ...values } = BRSR_FORM_FIELDS;
  const BRSR_FORM_FIELDS = isOnlyBrsr
    ? WITHOUT_ENVIORNMENT_BRSR_FORM_FIELDS
    : WITH_ENVIORNMENT_BRSR_FORM_FIELDS;

  return Object.values(BRSR_FORM_FIELDS)?.reduce(
    (acc, curr) => {
      const initialData = curr?.reduce((acc, curr) => {
        const temp = [];
        curr?.tables?.forEach(({ tr }) =>
          tr?.forEach((e) =>
            e?.forEach((f) => {
              if (
                ["number", "text", "checkbox", "dropdown", "textarea"].includes(
                  f?.type
                )
              ) {
                const exists = temp.find((ele) => ele.id === f.id);
                if (exists) {
                  exists.value = {
                    ...exists.value,
                    [f.valKey]: f.type === "checkbox" ? false : "",
                  };
                  return;
                }
                temp.push({
                  id: f.id,
                  key: f.key,
                  value: {
                    [f.valKey]: f.type === "checkbox" ? false : "",
                  },
                });
              }
            })
          )
        );
        return [...acc, ...temp];
      }, []);
      return [...acc, ...initialData];
    },
    []
  );
};

// Helper function to fetch a value for a specific dataId
const fetchValue = (data, dataId, key = ["Current Value"]) => {
  return parseFloat(data?.find((ele) => ele.id === dataId)?.value[key]) || 0;
};

// Helper function to sum a list of values based on their IDs
const sumValues = (data, ids) => {
  return ids.reduce((acc, dataId) => acc + fetchValue(data, dataId), 0);
};

// General calculation helper for revenue-related fields
const calculateRevenue = (data, sumIds, revenueId) => {
  const sumValue = sumValues(data, sumIds);
  const revenue = fetchValue(data, revenueId) || 1; // Prevent division by 0
  return (sumValue / revenue).toFixed(2);
};

export {
  formatDateForTable,
  addGroupHeadingData,
  groupByConsecutiveGroups,
  generateAqiPayload,
  getUnits,
  generatePayload,
  getFormDataUrl,
  getCompartaorForSorting,
  filterArray,
  getRoundedValue,
  generateYearArray,
  calculateMaxWithIncrease,
  convertToNestedFormat,
  updateField,
  getAllFieldValues,
  convertToNestedFormatEnv,
  getApprovalUnits,
  socialNestedData,
  findKpi,
  addChartToEditor,
  getBrsrInitialData,
  calculateRevenue,
  sumValues,
  fetchValue,
};

import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import fetchData from "../utils/fetchData";
import { getLocalStorage, setLocalStorage } from "../helpers/localStorage";

export default class CompanyStore {
  kpiName = "";
  createWaterMessage = null;
  createWaterError = null;
  getWaterError = null;
  water = null;
  companyData = [];
  catId = getLocalStorage("catId");
  subCatId = getLocalStorage("subCatId");
  kpiId = getLocalStorage("kpiId");
  companyUnit = getLocalStorage("companyUnit");
  companies = [];
  assignedCategory = [];
  unitsRaw = [];
  fieldsRaw = [];
  permissibleLimitsRaw = [];
  performanceRaw = [];
  revenueRaw = {};
  revenuePageDataRaw = {};
  performanceLoading = false;
  logListRaw = {};
  fetchFavAssigneRaw = [];
  fetchFavExisitRaw = [];
  constructor(rootStore) {
    this.companyUnit = getLocalStorage("companyUnit");
    this.catId = getLocalStorage("catId");
    this.subCatId = getLocalStorage("subCatId");
    this.kpiId = getLocalStorage("kpiId");
    this.rootStore = rootStore;
    if (getLocalStorage("kpiId")) {
      this.fetchKpiName(getLocalStorage("kpiId"));
    }
    makeObservable(this, {
      kpiName: observable,
      createWaterMessage: observable,
      createWaterError: observable,
      water: observable,
      catId: observable,
      subCatId: observable,
      kpiId: observable,
      companies: observable,
      assignedCategory: observable,
      assignedCategoryList: computed,
      companyUnit: observable,
      unitsRaw: observable,
      fieldsRaw: observable,
      permissibleLimitsRaw: observable,
      revenuePageDataRaw: observable,
      // actions
      createWater: action.bound,
      setCatId: action.bound,
      subCategoryList: computed,
      getAllWaters: action.bound,
      getWaterError: observable,
      waterList: computed,
      companyData: observable,
      getAllCompanyData: action.bound,
      setSubCatId: action.bound,
      setKpiId: action.bound,
      getCompanyData: action.bound,
      getCompanyAssignFields: action.bound,
      removeCompanyData: action.bound,
      setUnit: action.bound,
      setUnitData: action.bound,
      isCompanyUnit: computed,
      removeAllError: action.bound,
      companyDataJson: computed,
      reset: action.bound,
      units: computed,
      fetchUnits: action.bound,
      fetchFields: action.bound,
      fields: computed,
      setPermissibleLimit: action.bound,
      fetchFinancialData: action.bound,
      permissibleLimit: computed,
      createPerformance: action.bound,
      getPerformance: action.bound,
      performanceRaw: observable,
      performance: computed,
      revenueRaw: observable,
      revenue: computed,
      saveRevenueData: action.bound,
      fetchRevenue: action.bound,
      fetchRevenueCalculated: action.bound,
      fetchRevenueData: action.bound,
      revenuePageData: computed,
      performanceLoading: observable,
      fetchKpiName: action.bound,
      fetchLogActivity: action.bound,
      logListRaw: observable,
      fetchFavAssigne: action.bound,
      fetchFavAssigneRaw: observable,
      fetchFavAssigneLimit: computed,
      favCreate: action.bound,
      fetchFavExisit: action.bound,
      fetchFavExisitRaw: observable,
      fetchFavExisitLimit: computed,

    });
    reaction(
      () => this.kpiId,
      (id) => {
        this.fetchKpiName(id);
      }
    );
  }

  get fields() {
    return toJS(this.fieldsRaw);
  }

  get permissibleLimit() {
    return toJS(this.permissibleLimitsRaw);
  }


  get performance() {
    return toJS(this.performanceRaw);
  }

  get revenue() {
    return toJS(this.revenueRaw);
  }

  get revenuePageData() {
    return toJS(this.revenuePageDataRaw);
  }

  get logActivity() {
    return toJS(this.logListRaw);
  }
  get fetchFavAssigneLimit() {
    return toJS(this.fetchFavAssigneRaw);
  }
  get fetchFavExisitLimit() {
    return toJS(this.fetchFavExisitRaw);
  }

  reset() {
    this.createWaterMessage = null;
    this.createWaterError = null;
    this.getWaterError = null;
    this.water = null;
    this.companyData = [];
    this.catId = "";
    this.subCatId = "";
    this.kpiId = "";
    this.companyUnit = "";
    this.companies = [];
    this.assignedCategory = [];
    this.logListRaw = {};
  }

  get companyDataJson() {
    return toJS(this.companyData);
  }

  removeAllError() {
    this.createWaterError = null;
    this.getWaterError = null;
    this.createWaterMessage = null;
  }

  setCatId(payload) {
    this.catId = payload;
    setLocalStorage("catId", payload);
  }

  setSubCatId(payload) {
    this.subCatId = payload;
    setLocalStorage("subCatId", payload);
  }

  setKpiId(payload) {
    this.kpiId = payload;
    setLocalStorage("kpiId", payload);
  }

  removeCompanyData() {
    localStorage.removeItem("catId");
    localStorage.removeItem("subCatId");
    localStorage.removeItem("kpiId");
    this.catId = null;
    this.subCatId = null;
    this.kpiId = null;
  }

  get isCompanyUnit() {
    return !this.companyUnit;
  }

  get companiesList() {
    return toJS(this.companies);
  }

  get assignedCategoryList() {
    return toJS(this.assignedCategory);
  }

  get subCategoryList() {
    return toJS(this.companyData?.assignSubCategory)
      ?.filter((ele) => ele?.catId === this.catId)
      ?.map((ele) => {
        const kpi = toJS(this.companyData?.assignKpi)?.filter(
          (e) => e.catId === ele.catId && e.subCatId === ele.subCatId
        );
        return {
          ...ele,
          kpi,
        };
      });
  }

  get waterList() {
    return toJS(this.water);
  }

  createWater(payload, groupPayload) {
    const token = JSON.parse(
      localStorage.getItem("access-token") ||
        localStorage.getItem("admin-access-token")
    )?.token;
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    formData.append("groupPayload", JSON.stringify(groupPayload));
    payload.forEach((pay, index) => {
      if (pay.attachment) {
        formData.append(`file[]`, pay.attachment);
        formData.append(`index[]`, index);
      }
    });
    fetch(process.env.REACT_APP_BASE_URL + "add-water/create", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.createWaterMessage = "created";
          return;
        }
        this.createWaterError = data.message;
      })
      .catch((err) => {
        this.createWaterError = err.message;
        console.error(err);
      });
  }

  getAllWaters() {
    fetchData(
      "add-water/getAllWater?limit=20000&sortBy=field_group&sortOrder=ASC",
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.water = data.data;
          return;
        }
        this.getWaterError = data.message;
      })
      .catch((err) => {
        this.createError = err.message;
        console.error(err);
      });
  }

  getAllCompanyData(url) {
    if (url) {
      fetchData(url, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            this.companyData = data.data;
            return;
          }
          this.createError = data.message;
        })
        .catch((err) => {
          this.createError = err.message;
          console.error(err);
        });
    }
  }

  getCompanyData() {
    fetchData("company/companies", {})
      .then((response) => response.json())
      .then((result) => {
        this.companies = result.data.data;
      });
  }

  getCompanyAssignFields(companyId) {
    const body = JSON.stringify({
      companyId,
      catId: this.catId,
      subCatId: this.subCatId,
      kpiId: this.kpiId,
    });
    fetchData(`company/getCompanyAssignFields?limit=20000`, {
      method: "POST",
      body,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.assignedCategory = result.data;
        }
      })
      .catch((error) => console.error("Eroooroorororoor", error));
  }

  setUnit(payload) {
    this.companyUnit = payload;
    setLocalStorage("companyUnit", payload);
  }

  setUnitData(payload) {
    this.companyUnit = payload;
    setLocalStorage("companyUnit", payload);
  }


  fetchUnits() {
    fetchData(`unit-master/getAll`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.unitsRaw = result.data?.map(
            ({ id, name, position, shortName }) => ({
              id,
              name,
              position,
              shortName,
            })
          );
        }
      })
      .catch((error) => console.error("Eroooroorororoor", error));
  }

  get units() {
    return toJS(this.unitsRaw);
  }
}

CompanyStore.prototype.fetchFields = function () {
  const body = JSON.stringify({
    catId: this.catId,
    subCatId: this.subCatId,
    kpiId: this.kpiId,
  });
  fetchData(`company/getAssignedFields`, {
    method: "POST",
    body,
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.status) {
        this.fieldsRaw = result.data;
      }
    })
    .catch((error) => console.error("Eroooroorororoor", error));
};

CompanyStore.prototype.setPermissibleLimit = function ({
  showAlert,
  payload,
  setShow,
  year,
}) {
  const body = JSON.stringify(
    payload.map(({ group, value, unit }) => ({
      group,
      value,
      kpiId: this.kpiId,
      catId: this.catId,
      subCatId: this.subCatId,
      year,
      unitId: unit,
    }))
  );
  fetchData("permissible/create", {
    method: "POST",
    body: body,
  })
    .then((response) => response.json())
    .then((result) => {
      showAlert?.(result.message, result.status);
      if (result.status) setShow(false);
    })
    .catch((error) => {
      showAlert?.("Internal Server Error");
      console.error("[Error]", error);
    });
};

CompanyStore.prototype.fetchFinancialData = function ({ year,payloadData }) {
  // const body = JSON.stringify({
  //   // kpiId: this.kpiId || null,
  //   // subCatId: this.subCatId || null,
  //   // catId: this.catId || null,
  //   year: `${year || new Date().getFullYear()}`,
  // });
  fetchData(`permissible/getAll${year ? `?year=${year}` : ""}`, {
    method: "POST",
    body: JSON.stringify(payloadData),
  })
    .then((res) => res.json())
    .then(({ status, data }) => {
      if (status) this.permissibleLimitsRaw = data;
    })
    .catch((err) => console.error("[Error]", err));
};

CompanyStore.prototype.fetchFavAssigne = function () {
  fetchData(`favourite/getAllAssigne`, {
    method: "POST",
  })
  .then((res) => res.json())
  .then((res) => {
    if (res.status) {
      // return res.data;
      this.fetchFavAssigneLimit = res.data;
    }
  });
};

CompanyStore.prototype.createPerformance = function ({
  payload,
  type,
  showAlert,
  setShow,
  year,
}) {
  fetchData(`company/performance?type=${type}&year=${year}`, {
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((result) => {
      if (result.status) {
        setShow(false);
      }
      showAlert?.(result.message, result.status);
    })
    .catch((err) => {
      showAlert?.("Internal Server Error");
    });
};

CompanyStore.prototype.getPerformance = function ({ type, year }) {
  this.performanceLoading = true;
  const params = new URLSearchParams({
    ...(type && { type }),
    year,
  });
  fetchData(`company/performance?${params.toString()}`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((result) => {
      if (result.status) {
        this.performanceRaw = result.data;
        this.performanceLoading = false;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

CompanyStore.prototype.saveRevenueData = function ({
  showAlert,
  fieldData,
  year,
  revenue,
}) {
  const field = fieldData?.map((ele) => ({ ...ele, year }));
  const body = JSON.stringify({
    fieldData: field,
    revenue,
  });

  fetchData(`revenue/create?year=${year}&companyUnit=${this.companyUnit}`, {
    method: "POST",
    body,
  })
    .then((response) => response.json())
    .then((result) => {
      showAlert?.(result.message, result.status);
      if (result.status) {
        window.location.reload();
      }
    })
    .catch((error) => {
      showAlert?.("Internal Server Error");
      console.error("[Error] Fetching data:", error);
    });
};

CompanyStore.prototype.fetchRevenue = function ({ year }) {
  const body = JSON.stringify({
    year: `${year || new Date().getFullYear()}`,
  });
  fetchData(`revenue/getAll${year ? `?year=${year}` : ""}`, {
    method: "POST",
    body,
  })
    .then((res) => res.json())
    .then(({ status, data }) => {
      if (status) this.revenueRaw = data;
    })
    .catch((err) => console.error("[Error]", err));
};

CompanyStore.prototype.fetchRevenueCalculated = function ({ year }) {
  const params = new URLSearchParams({
    year,
  });
  fetchData(`revenue/fetchForRevenue?${params.toString()}`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((result) => {
      if (result.status) {
        this.revenueRaw = result.data;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

CompanyStore.prototype.fetchRevenueData = function ({ year }) {
  // const urlParams = new URLSearchParams({
  //   companyUnit: this.companyUnit,
  //   ...(year ? { year } : {}),
  // });
  const {
    dashboardStore: { showAll },
  } = this.rootStore;
  const params = new URLSearchParams();
  params.append("year", year);
  if (!showAll) {
    params.append("companyUnit", this.companyUnit);
  }
  fetchData(`revenue/getAll?${params.toString()}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.status) {
        this.revenuePageDataRaw = res.data;
      }
    })
    .catch((err) => console.error(err));
};

CompanyStore.prototype.fetchKpiName = function (id) {
  if (id) {
    const params = new URLSearchParams({ id });
    fetchData(`kpi/name?${params.toString()}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          this.kpiName = res.data;
        }
      })
      .catch((err) => {
        console.error("[Error]", err);
      });
  }
};

CompanyStore.prototype.fetchLogActivity = function ({ page, pageSize, filter }) {
  const body = JSON.stringify(filter);
  const params = new URLSearchParams({page, pageSize });
  fetchData(`company/logList?${params.toString()}`, {
    method: "POST",
    body,
  })
    .then((res) => res.json())
    .then((res) => {
      this.logListRaw = res.data;
    })
    .catch((err) => {
      console.error("[Error]", err);
    });
};


CompanyStore.prototype.fetchFavAssigne = function () {
  fetchData(`favourite/getAllAssigne`, {
    method: "POST",
  })
    .then((res) => res.json())
    .then(({ status, data }) => {
      if (status) this.fetchFavAssigneRaw = data;
    })
    .catch((err) => console.error("[Error]", err));
};

CompanyStore.prototype.favCreate = function ({
  showAlert,
  payload,
}) {
  const body = JSON.stringify(payload);
  fetchData("favourite/create", {
    method: "POST",
    body: body,
  })
    .then((response) => response.json())
    .then((result) => {
      showAlert?.(result.message, result.status);
      if (result.status) {
        window.location.reload();
      }
    })
    .catch((error) => {
      showAlert?.("Internal Server Error");
      console.error("[Error]", error);
    });
};

CompanyStore.prototype.fetchFavExisit = function () {
  fetchData(`favourite/getAll`, {
    method: "POST",
  })
    .then((res) => res.json())
    .then(({ status, data }) => {
      if (status) this.fetchFavExisitRaw = data;
    })
    .catch((err) => console.error("[Error]", err));
};


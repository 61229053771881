import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useStore from "../../../hooks/useStore";
import {
  CHARTS_LABEL,
  STATIC_GROUPS,
  TARGET_LABEL,
} from "../../../constants/constants";
import { toJS } from "mobx";
import {
  calculateMaxWithIncrease,
  getRoundedValue,
} from "../../../utils/helpers";
import ColumnChart from "../../../components/charts/columnChart";
import ChartImportButton from "./chartImportButton";
import DonutChart from "../../../components/charts/donutChart";

const Charts = ({ editor }) => {
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => setShow(false), []);
  const handleOpen = useCallback(() => setShow((p) => !p), []);
  return (
    <>
      <div className="d-flex justify-content-sm-between">
        <button className="btn btn-primary" onClick={handleOpen}>
          <i className="mdi mdi-plus"></i> Import Charts
        </button>
      </div>
      {show && (
        <div className="chart-modal-overlay">
          <div className="chart-modal-content">
            <div
              style={{
                position: "relative",
              }}
            >
              <h4>Charts</h4>
              <span
                style={{
                  paddingTop: "16px",
                  fontWeight: 900,
                  fontSize: "16px",
                  position: "absolute",
                  cursor: "pointer",
                  right: 20,
                  zIndex: 100,
                  top: -8,
                }}
                onClick={handleClose}
              >
                X
              </span>
              <WaterCharts editor={editor} />
              <WasteCharts editor={editor} />
              <EmissionCharts editor={editor} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const WaterCharts = observer(({ editor }) => {
  const year = new Date().getFullYear();
  const {
    dashboardStore: { fetchDashboardDetails, dashboardDetails },
    companyStore: { fetchFinancialData, permissibleLimit, companyData },
  } = useStore();
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchFinancialData({ year });
    fetchDashboardDetails({
      signal,
      year,
      all: true,
    });
    return () => {
      abortController.abort();
    };
  }, [fetchDashboardDetails, fetchFinancialData, year]);

  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.water;
    const result = ["A", "B", "C"].map((group) => {
      return {
        name: STATIC_GROUPS.water[group],
        data: [year - 1, year]?.map(
          (year) =>
            tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
              group
            ]?.prevValue || 0
        ),
        target: [year - 1, year]?.map(
          (year) =>
            tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
              group
            ]?.target || 0
        ),
      };
    });
    return result;
  }, [dashboardDetails?.charts?.water, year]);

  const assignedKpi = toJS(companyData)?.assignKpi?.find(
    (e) => e.kpiIdAssignKpi.name === "Water"
  );

  const limits = permissibleLimit?.filter(
    ({ kpiId, subCatId, catId, year: tempYear }) =>
      kpiId === assignedKpi?.kpiId &&
      catId === assignedKpi?.catId &&
      subCatId === assignedKpi?.subCatId &&
      tempYear == year
  );
  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.water?.[year];
    const result = ["A", "B", "C"].map(
      (group) =>
        tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
          ?.prevValue || 0
    );
    const labels = ["A", "B", "C"].map((group) => STATIC_GROUPS.water[group]);
    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.water, year]);
  let maxLimitValue;

  if (limits && limits.length > 0) {
    maxLimitValue = Math.max(0, ...limits.map((ele) => ele.value));
  } else {
    const allValues = ["A", "B", "C"].flatMap((group) =>
      [year - 1, year].map(
        (year) =>
          dashboardDetails?.charts?.water?.[year]?.reduce(
            (acc, curr) => ({ ...acc, ...curr }),
            {}
          )[group]?.prevValue || 0
      )
    );
    maxLimitValue = Math.max(0, ...allValues);
  }
  const maxValueWithIncrease = calculateMaxWithIncrease(
    { max: maxLimitValue },
    "max",
    "Inc"
  );
  return (
    <>
      <div className=" d-flex  gap-5  flex-column">
        <div
          className="p-4 border chart-pop  rounded "
          id="editor-chart-water-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-water-1" />
          </div>
          <ColumnChart
            max={maxValueWithIncrease}
            data={
              columnChartData?.map(({ data, name }) => ({
                data,
                name,
              })) || []
            }
            unit={dashboardDetails?.charts?.water?.unit || "KL"}
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
            yaxis={
              limits?.map((ele) => ({
                y: ele.value || 0, // Target value
                borderColor: "#FF4560",
                label: {
                  borderColor: "#FF4560",
                  style: {
                    color: "#fff",
                    background: "#FF4560",
                  },
                  text: `${TARGET_LABEL.water[ele.group]}: ${ele.value} ${
                    ele.unitIdMaster.short_name
                  }`,
                },
              })) || []
            }
          />
        </div>

        <div
          id="editor-chart-water-2"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-water-2" />
          </div>
          <DonutChart
            data={doughNutChartData.data || []}
            labels={doughNutChartData.labels || []}
          />
        </div>
      </div>
    </>
  );
});

const WasteCharts = observer(({ editor }) => {
  const year = new Date().getFullYear();
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { permissibleLimit, companyData },
  } = useStore();
  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.waste;
    const result = ["A", "D", "E"].map((group) => {
      return {
        name: CHARTS_LABEL.waste[group],
        data: [year - 1, year]
          ?.map(
            (year) =>
              tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
          )
          ?.map((e) => getRoundedValue(e)),
        target: [year - 1, year]
          ?.map(
            (year) =>
              tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.target || 0
          )
          ?.map((e) => getRoundedValue(e)),
      };
    });
    return result;
  }, [dashboardDetails?.charts?.waste, year]);

  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.waste?.[year];
    const result = ["A", "D", "E"].map(
      (group) =>
        tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
          ?.prevValue || 0
    );
    const labels = ["A", "D", "E"].map((group) => CHARTS_LABEL.waste[group]);
    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.waste, year]);

  const assignedKpi = toJS(companyData)?.assignKpi?.find(
    (e) => e.kpiIdAssignKpi.name === "Waste"
  );

  const limits = permissibleLimit?.filter(
    ({ kpiId, subCatId, catId, year: tempYear }) =>
      kpiId === assignedKpi?.kpiId &&
      catId === assignedKpi?.catId &&
      subCatId === assignedKpi?.subCatId &&
      year == tempYear
  );

  let maxLimitValue;

  if (limits && limits.length > 0) {
    maxLimitValue = Math.max(0, ...limits.map((ele) => ele.value));
  } else {
    const allValues = ["A", "D", "E"].flatMap((group) =>
      [year - 1, year].map(
        (year) =>
          dashboardDetails?.charts?.waste?.[year]?.reduce(
            (acc, curr) => ({ ...acc, ...curr }),
            {}
          )[group]?.prevValue || 0
      )
    );
    maxLimitValue = Math.max(0, ...allValues);
  }

  const maxValueWithIncrease = calculateMaxWithIncrease(
    { max: maxLimitValue },
    "max",
    "Inc"
  );
  return (
    <>
      <div className=" d-flex  gap-5 mt-5  flex-column">
        <div
          id="editor-chart-waste-1"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-waste-1" />
          </div>
          <ColumnChart
            max={maxValueWithIncrease}
            data={
              columnChartData?.map(({ data, name }) => ({
                data: data.map((e) => getRoundedValue(e, 0)),
                name,
              })) || []
            }
            unit={dashboardDetails?.charts?.waste?.unit}
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
            yaxis={
              limits?.map((ele) => ({
                y: getRoundedValue(ele.value) || 0, // Target value
                borderColor: "#FF4560",
                label: {
                  borderColor: "#FF4560",
                  style: {
                    color: "#fff",
                    background: "#FF4560",
                  },
                  text: `${TARGET_LABEL.waste[ele.group]}: ${ele.value} ${
                    ele.unitIdMaster.short_name
                  }`,
                },
              })) || []
            }
          />
        </div>

        <div
          id="editor-chart-waste-2"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-waste-2" />
          </div>
          <DonutChart
            data={doughNutChartData.data || []}
            labels={doughNutChartData.labels || []}
          />
        </div>
      </div>
    </>
  );
});

const EmissionCharts = observer(({ editor }) => {
  const year = new Date().getFullYear();
  const {
    dashboardStore: { dashboardDetails },
  } = useStore();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi;
    const result = ["A1", "B1"].map((group) => {
      return {
        name: CHARTS_LABEL.energy[group],
        data: [year - 1, year]?.map((year) =>
          group === "C"
            ? tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )["C1"]?.prevValue ||
              0 +
                tempData?.[year]?.reduce(
                  (acc, curr) => ({ ...acc, ...curr }),
                  {}
                )["C2"]?.prevValue ||
              0
            : tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
        ),
        target: [year - 1, year]?.map((year) =>
          group === "C"
            ? tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )["C1"]?.prevValue ||
              0 +
                tempData?.[year]?.reduce(
                  (acc, curr) => ({ ...acc, ...curr }),
                  {}
                )["C2"]?.prevValue ||
              0
            : tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
        ),
      };
    });
    // if (tempData?.totalGhgEmission) {
    //   result.push(tempData.totalGhgEmission);
    // }
    if (tempData?.Scope3) {
      result.push(tempData.Scope3);
    }
    if (tempData?.totalGhgEmissionFinished) {
      result.push(tempData.totalGhgEmissionFinished);
    }
    return result;
  }, [dashboardDetails?.charts?.aqi, year]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi?.[year];
    const result = ["A1", "B1"].map((group) =>
      group === "C"
        ? tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.["C1"]
            ?.prevValue ||
          0 +
            tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.["C2"]
              ?.prevValue ||
          0 + tempData?.["Scope3"]?.data[0] ||
          0
        : tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
            ?.prevValue || 0
    );
    const labels = ["A1", "B1"].map((group) => CHARTS_LABEL.energy[group]);
    // if (dashboardDetails?.charts?.aqi?.ghgEmissionIntensity) {
    //   result.push(dashboardDetails?.charts?.aqi?.ghgEmissionIntensity?.data[1]);
    //   labels.push("GHG Emission Intensity/Revenue");
    // }
    //  if (dashboardDetails?.charts?.aqi?.totalGhgEmissionFinished) {
    //   result.push(dashboardDetails?.charts?.aqi?.totalGhgEmissionFinished?.data[1]);
    //   labels.push("Total GHG Emission Intensity/Finished goods quantity produced");
    // }
    if (dashboardDetails?.charts?.aqi?.Scope3) {
      result.push(dashboardDetails?.charts?.aqi?.Scope3?.data[1]);
      labels.push("Scope 3 Emission");
    }

    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.aqi, year]);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <div
          id="editor-charts-emission-1"
          className="p-4 border chart-pop  rounded"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-emission-1" />
          </div>

          <ColumnChart
            columnWidth={"100%"}
            data={
              columnChartData?.map(({ data, name }) => ({
                data: data.map((e) => getRoundedValue(e, 0)),
                name,
              })) || []
            }
            unit={dashboardDetails?.charts?.aqi?.unit}
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
            yaxis={[]}
          />
        </div>

        <div
          id="editor-chart-emission-2"
          className="p-4 border chart-pop  rounded"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-chart-emission-2"
              style={{
                width: "400px",
              }}
            />
          </div>
          <DonutChart
            data={doughNutChartData.data || []}
            labels={doughNutChartData.labels || []}
            unit={dashboardDetails?.charts?.aqi?.unit}
          />
        </div>
      </div>
    </>
  );
});

export default observer(Charts);

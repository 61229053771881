import { observer } from "mobx-react";
import useStore from "../../../../hooks/useStore";
import { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const LogBody = () => {
  const {
    formStore: { log },
  } = useStore();
  const [modalOpen, setModalOpen] = useState(false);
  const [view, setView] = useState(null);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <tbody>
        {log.length === 0 ? (
          <tr>
            <td colSpan={5}>
              <div className="text-center">No records found</div>
            </td>
          </tr>
        ) : (
          log?.map((logItem, index) => (
            <tr key={index}>
              <td>{logItem?.user?.firstName}</td>
              <td>{new Date(logItem.createdAt).toLocaleDateString("en-GB")}</td>
              <td>{logItem.action}</td>
              <td>{logItem.ip}</td>
              <td>
                <button
                  onClick={() => {
                    setModalOpen(true);
                    setView(logItem.request);
                  }}
                >
                  View
                </button>
              </td>
            </tr>
          ))
        )}
      </tbody>
      <Modal show={modalOpen} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Log Details</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {view ? (
            <>
              <p>
                <strong>Log Data:</strong>
              </p>
              <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                {view?.body?.data?.map((e) => (
                  <div
                    style={{
                      backgroundColor: "#f8f9fa",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <RenderObject data={e} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p>No details available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const RenderObject = ({ data, level = 0 }) => {
  return (
    <div
      style={{
        marginBottom: "10px",
        paddingLeft: `${level * 20}px`, // Indentation for nested objects
        borderLeft: level > 0 ? "1px solid #ccc" : "none", // Add border for nested levels
      }}
    >
      {Object.entries(data).map(([key, value], index) => (
        <>
          <h5>{key}:</h5>
          <p>
            {typeof value === "object" ? (
              <RenderObject data={value} level={level + 1} />
            ) : !value ? (
              "-"
            ) : (
              value
            )}
          </p>
        </>
      ))}
    </div>
  );
};

export default observer(LogBody);

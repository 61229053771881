import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../utils/layout";
import profile from "../../assets/images/users/user-dummy-img.jpg";
// import user from "../../assets/images/users/avatar-1.jpg";
import { Button, Form } from "react-bootstrap";
import { useAuth } from "../../context/authProvider";
import  AddFavourite from "./AddFavourite";

const CompnayProfile = () => {
  const { showAlert } = useAuth();
  const [data, setData] = React.useState({});
  const [password, setPassword] = React.useState({});

  const [validated, setValidated] = React.useState(false);
  const [validatedPass, setValidatedPass] = React.useState(false);
  const editProfileHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleupdate(data);
    }

    setValidated(true);
  };
  const resetPasswordHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (password?.confirmPassword !== password?.confirmPassword2) {
      showAlert("New Password and Confirm Password does not match", false);
    } else {
      handleupdate({ password: password?.confirmPassword });
    }

    setValidatedPass(true);
  };
  const handleupdate = (data) => {
    fetch(process.env.REACT_APP_BASE_URL + "company/updatecompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("access-token"))?.token
        }`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        showAlert(result.msg || result.message, result.status || false);
      });
  };
  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL + "company/company", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("access-token"))?.token
        }`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setData(data.data);
          return;
        }
      });
  }, []);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handlePasswordChange = ({ target: { name, value } }) =>
    setPassword((prev) => ({ ...prev, [name]: value }));

  const isMaxEmployees = data?.employeeCount === data?.allowedEmployee;

  return (
    <Layout>
      <div className="profile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Profile</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/company/dashboard">Company</Link>
                    </li>
                    <li className="breadcrumb-item active">Profile</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-4">
              {/* Card 1 */}
              <div className="card   overflow-hidden">
                <div className="bg-primary-subtle  welcome-head">
                  <div className="row  welcome">
                    <div className="col-7 welcome-text ">
                      <div className=" p-3 text-white  ">
                        <h5 className=" ">Welcome {data?.firstName},</h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end profile-container lg-d-none ">
                      <img src={profile} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-sm-4 company-profile-section ">
                      <div className="avatar-md profile-user-wid mb-2">
                        {/* <img
                          src={user}
                          alt=""
                          className="img-thumbnail rounded-circle"
                        /> */}
                        <span className="avatar-title rounded-circle bg-light text-dark font-size-24 fw-bold">
                          {data?.companyName?.slice(0, 1)}
                        </span>
                      </div>
                      <h5 className="font-size-15 text-truncate">
                        {data?.companyName}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">Company</p>
                    </div>

                    <div className="col-sm-8">
                      <div className="pt-4">
                        <div className="row text-center">
                          <div className="col-6">
                            <h5 className="font-size-15 font-weight ">
                              {data?.employeeRange}
                            </h5>
                            <p className="text-muted mb-0">Employee Range</p>
                          </div>
                          <div className="col-6">
                            <h5
                              className="font-size-15 font-weight"
                              style={{
                                color: isMaxEmployees ? "red" : "black",
                              }}
                            >
                              {data?.employeeCount} / {data?.allowedEmployee}
                            </h5>
                            <p className="text-muted mb-0">Allowed Employee</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card 2 */}
              <div className="card  ">
                <div className="d-flex px-4 pt-4">
                  <h4 className="card-title text-dark ">
                    Personal Information
                  </h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{data?.firstName + " " + data?.lastName}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{data?.phoneNumber}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{data?.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{data?.address}</td>
                        </tr>
                        <tr>
                          <th scope="row">CIN No :</th>
                          <td>{data?.cinNumber ?? "NA"}</td>
                        </tr>
                        <tr>
                          <th scope="row">Paid-up Cpital :</th>
                          <td>{data?.paidUpCapital ?? "NA"}</td>
                        </tr>
                        <tr>
                          <th scope="row">Name of the Stock Exchange(s) where shares are listed :</th>
                          <td>{data?.nameOfStoke ?? "NA"}</td>
                        </tr>
                        <tr>
                          <th scope="row">Unit :</th>
                          <td>
                            {data?.companyIdAssignUnit?.length > 0 ? (
                              <ul>
                                {data.companyIdAssignUnit.map((unit, index) => (
                                  <li key={index}>{unit.name}</li>
                                ))}
                              </ul>
                            ) : data?.companyAssignUnits?.length > 0 ? (
                              <ul>
                                {data.companyAssignUnits.map((unit, index) => (
                                  <li key={index}>{unit.name}</li>
                                ))}
                              </ul>
                            ) : (
                              "No units assigned"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Second Column */}
            <div className="col-xl-8">
              <div className="card ">
                <div className="d-flex px-4 pt-4">
                  <h4 className="card-title text-dark ">Edit Profile</h4>
                </div>
                <div className="card-body">
                  <div className="p-2">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={editProfileHandler}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first name"
                          value={data?.firstName}
                          onChange={handleChange}
                          name="firstName"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last name"
                          value={data?.lastName}
                          onChange={handleChange}
                          name="lastName"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter phone number"
                          value={data?.phoneNumber}
                          onChange={handleChange}
                          name="phoneNumber"
                          onWheel={(e) => {
                            e.target.blur();
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.slice(0, 10);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={data?.email}
                          // onChange={handleChange}
                          name="email"
                          readOnly
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-md float-end"
                      >
                        Save
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>

              <div className="card  ">
                <div className="d-flex px-4 pt-4 align-center">
                  <h4 className="card-title text-dark  ">Reset Password</h4>
                </div>
                <div className="card-body">
                  <div className="p-2">
                    <Form
                      noValidate
                      validated={validatedPass}
                      onSubmit={resetPasswordHandler}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter old password"
                          value={password?.password}
                          onChange={handlePasswordChange}
                          name="password"
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter new password"
                          value={password?.confirmPassword}
                          onChange={handlePasswordChange}
                          name="confirmPassword"
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm password"
                          value={password?.confirmPassword2}
                          onChange={handlePasswordChange}
                          name="confirmPassword2"
                          required
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-md float-end"
                      >
                        Reset
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data?.role === "employee" && (
          <div className="row justify-content-center">
          <AddFavourite />
          </div>                
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CompnayProfile;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Login from "./pages/auth/login";
import Dashboard from "./pages/dashboard";
import NotFound from "./pages/notFound";
import "react-datepicker/dist/react-datepicker.css";
import Signup from "./pages/auth/signup";
import ResetPassword from "./pages/auth/ResetPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Charts from "./pages/charts";
import Categories from "./pages/categories";
import SubCategories from "./pages/subcategories";
import Kpi from "./pages/kpi";
import Fields from "./pages/fields";
import AssignPage from "./pages/assignFields";
import Unit from "./pages/unit";
import { observer } from "mobx-react";
import PrivateRoutes from "./utils/protectedRoutes";
import CompanyList from "./pages/company/company";
import AddCompany from "./pages/company/AddCompanyPage";
import WaterDashboardPage from "./pages/company/utlityWater";
import AddWaterForm from "./pages/utilityWater/water/addWaterForm";
import CompanyLogin from "./pages/authCompany/login";
import RainWater from "./pages/utilityWater/rain-water/RainWater";
import WaterConsumption from "./pages/utilityWater/water-consumption/WaterConsumption";
import AddWaterConsumption from "./pages/utilityWater/water-consumption/AddWaterConsumption";
import UnitSelectModal from "./utils/unitModal";
import DataPage from "./pages/dataPage";
import CompnayProfile from "./pages/company-pages/Company-Profile";
import Layout from "./utils/layout";
import WasteDashboard from "./pages/company/utilityWaste/waste";
import EmployeePage from "./pages/company/addEmployee";
import AddEmployeePage from "./pages/company/addEmployee/AssignTabs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/icons.min.css";
import "./assets/css/app.min.css";
import AddWastePage from "./pages/company/utilityWaste/waste/addWaste";
import EnergyDashboard from "./pages/company/utilityEnergy/energy";
import AddEnergy from "./pages/company/utilityEnergy/energy/addEnergy";
import BiodiversityDashboard from "./pages/company/biodiversity/biodiversity";
import AddBiodiversity from "./pages/company/biodiversity/biodiversity/addBiodiversity";
import Aqi from "./pages/company/utilityEmission/aqi";
import AddAqiPage from "./pages/company/utilityEmission/aqi/add/index";
import BasicParameter from "./pages/basicParameter";
import EconomicPerformance from "./pages/governance/economicPerformance";
import GovernanceParameters from "./pages/governance/governanceParameters";
import GovernanceKpis from "./pages/governance/governanceKpis";
import {
  GOVERNANCE_KPIS_DROPDOWN_CONSTANTS,
  GOVERNANCE_ROUTE_CONSTANTS,
  SOCIAL_ROUTE_CONSTANT,
} from "./constants/constants";
import AddWater from "./pages/company/utlityWater/addWater";
import Revenue from "./pages/company/revenue";
import AddSocial from "./pages/company/social/addSocial";
import EnvironmentApproval from "./pages/company/approvalForms/environmentApproval";
import SocialApproval from "./pages/company/approvalForms/socialApproval";
import GovernanceApproval from "./pages/company/approvalForms/governanceApproval";
import BasicParameterApproval from "./pages/company/approvalForms/basicParameterApproval";
import LogActivity from "./pages/company/LogActivity";
import Report from "./pages/report";
import BrsrPage from "./pages/company/brsr/addBrsr";

const wrapWithLayout = (Component) => <Layout>{Component}</Layout>;

function App() {
  return (
    <div className="App">
      <Router>
        <UnitSelectModal />
        <Routes>
          <Route path="/" element={<CompanyLogin />} />
          <Route exact path="reset-password" element={<ResetPassword />} />
          <Route exact path="forgot-password" element={<ForgotPassword />} />
          <Route path="/company" element={<Outlet />}>
            <Route index element={<CompanyLogin />} />

            <Route element={<PrivateRoutes />}>
              <Route exact path="profile" element={<CompnayProfile />} />
              <Route exact path="dashboard" element={<Dashboard />} />
              <Route exact path="water" element={<WaterDashboardPage />} />
              <Route
                exact
                path="add-water"
                element={wrapWithLayout(<AddWater />)}
              />
              <Route exact path="revenue">
                <Route index element={<Revenue />} />
              </Route>
              <Route exact path="employee" element={<Outlet />}>
                <Route index element={<EmployeePage />} />
                <Route
                  exact
                  path="add-employee"
                  element={<AddEmployeePage />}
                />
              </Route>
              <Route exact path="logList">
                <Route index element={<LogActivity />} />
              </Route>
              <Route path="data" element={<DataPage />} />
              <Route path="waste" element={<Outlet />}>
                <Route
                  exact
                  index
                  element={wrapWithLayout(<WasteDashboard />)}
                />
                <Route
                  exact
                  path="add-waste"
                  element={wrapWithLayout(<AddWastePage />)}
                />
              </Route>
              <Route path="energy" element={<Outlet />}>
                <Route
                  exact
                  index
                  element={wrapWithLayout(<EnergyDashboard />)}
                />
                <Route
                  exact
                  path="add"
                  element={wrapWithLayout(<AddEnergy />)}
                />
              </Route>
              <Route path="biodiversity" element={<Outlet />}>
                <Route
                  exact
                  index
                  element={wrapWithLayout(<BiodiversityDashboard />)}
                />
                <Route
                  exact
                  path="add"
                  element={wrapWithLayout(<AddBiodiversity />)}
                />
              </Route>
              <Route path="aqi" element={<Outlet />}>
                <Route exact index element={wrapWithLayout(<Aqi />)} />
                <Route
                  exact
                  path="add"
                  element={wrapWithLayout(<AddAqiPage />)}
                />
              </Route>
              <Route path="basic_parameter" element={<Outlet />}>
                <Route
                  exact
                  index
                  element={wrapWithLayout(<BasicParameter />)}
                />
                {/* <Route
                  exact
                  path="add"
                  element={wrapWithLayout(<AddAqiPage />)}
                /> */}
              </Route>

              <Route path="approve" element={<Outlet />}>
                <Route
                  path="environment"
                  element={wrapWithLayout(<EnvironmentApproval />)}
                />
                <Route
                  path="social"
                  element={wrapWithLayout(<SocialApproval />)}
                />
                <Route
                  path="governance"
                  element={wrapWithLayout(<GovernanceApproval />)}
                />
                <Route
                  path="basic"
                  element={wrapWithLayout(<BasicParameterApproval />)}
                />
              </Route>

              <Route path="report" element={wrapWithLayout(<Report />)} />

              {GOVERNANCE_ROUTE_CONSTANTS.map((ele) => (
                <Route
                  path={ele.routeSubPath}
                  key={`${ele.routeSubPath}-${ele.groupConstant}`}
                  element={wrapWithLayout(
                    <>
                      {ele.routeSubPath === "governance_parameters" ? (
                        <GovernanceParameters
                          dropDownValues={ele.dropDownValues}
                          routeSubPath={ele.routeSubPath}
                          groupConstant={ele.groupConstant}
                          key={`${ele.routeSubPath}-${ele.groupConstant}`}
                          label={ele.label}
                        />
                      ) : (
                        <EconomicPerformance
                          dropDownValues={ele.dropDownValues}
                          routeSubPath={ele.routeSubPath}
                          groupConstant={ele.groupConstant}
                          key={`${ele.routeSubPath}-${ele.groupConstant}`}
                          label={ele.label}
                        />
                      )}
                    </>
                  )}
                  exact
                />
              ))}
              {SOCIAL_ROUTE_CONSTANT.map((ele) => (
                <Route
                  path={ele.routeSubPath}
                  element={wrapWithLayout(
                    <AddSocial
                      key={`${ele.groupConstant}-${ele.routeSubPath}-social`}
                      groupConstant={ele.groupConstant}
                      routeSubPath={ele.routeSubPath}
                    />
                  )}
                  key={`${ele.groupConstant}-${ele.routeSubPath}-social`}
                />
              ))}

              <Route
                path="governance_kpis"
                key={`governance kpis-governance_kpis`}
                element={wrapWithLayout(
                  <GovernanceKpis
                    dropDownValues={GOVERNANCE_KPIS_DROPDOWN_CONSTANTS}
                    routeSubPath="governance_kpis"
                    key={`governance kpis-governance_kpis`}
                    groupConstant="GovernanceKpis"
                    label="Governance Kpis"
                  />
                )}
              />
              <Route path="brsr" 
                  element={wrapWithLayout(<BrsrPage />)}
              />
            </Route>
          </Route>

          <Route path="/admin" element={<Outlet />}>
            <Route exact index element={<Login />} />
            <Route exact path="signup" element={<Signup />} />
            {/* <Route exact path="reset-password" element={<ResetPassword />} />
            <Route
                exact
                path="forgot-password"
                element={<ForgotPassword />}
              /> */}
            <Route element={<PrivateRoutes admin />}>
              <Route exact path="dashboard" element={<Dashboard />} />
              <Route exact path="charts" element={<Charts />} />
              <Route path="data" element={<DataPage />} />

              <Route exact path="profile" element={<CompnayProfile />} />
              <Route exact path="categories" element={<Categories />} />
              <Route exact path="subcategories" element={<SubCategories />} />
              <Route exact path="kpi" element={<Kpi />} />
              <Route exact path="fields" element={<Fields />} />
              <Route exact path="assign" element={<AssignPage />} />
              <Route exact path="unit" element={<Unit />} />
              <Route exact path="company" element={<CompanyList />} />
              <Route exact path="add-company" element={<AddCompany />} />
              <Route exact path="water" element={<WaterDashboardPage />} />
              <Route exact path="add-water" element={<AddWaterForm />} />
              <Route exact path="rain-water" element={<RainWater />} />
              <Route
                exact
                path="add-water-consumption"
                element={<AddWaterConsumption />}
              />

              <Route
                exact
                path="water-consumption"
                element={<WaterConsumption />}
              />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default observer(App);

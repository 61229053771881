import { observer } from "mobx-react";
import Editor from "./components/editor";
import React, { useEffect, useRef, useState } from "react";
import Tools from "./components/tools";
import ReactDOMServer from "react-dom/server";
import InitialContent from "./components/initialContent";
import fetchData from "../../utils/fetchData";

import Template from "./components/newContent/Text";

const Report = () => {
  const ref = useRef(null);
  const editor = ref.current;
  const [data, setData] = useState({});
  useEffect(() => {
    fetchData("company/initialContent", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.error("[Error]", err);
      });
  }, []);

  const initialValue = ReactDOMServer.renderToStaticMarkup(
    // <InitialContent data={data} />
    <Template />
  );
  return (
    <div className="card p-4">
      <div className="d-flex justify-content-between align-items-center">
        <h3>Reports </h3>
        <Tools editor={editor} />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <div
          className="report-editor"
          style={{
            width: "100%",
            height: "calc(100vh - 300px)",
          }}
        >
          {data && <Editor ref={ref} initialValue={initialValue} />}
        </div>
      </div>
    </div>
  );
};

export default observer(Report);
